

import axios from 'axios';
import appConfig from '@/app_config';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import structuredClone from '@ungap/structured-clone';

function dateRangeString(dateFrom, dateTo){
	let dateFromString = "", dateToString=""
	if(dateTo){
		let dayTo=dateTo.substr(8,2)
		let monthTo=dateTo.substr(5,2)
		let yearTo=dateTo.substr(0,4)
		dateToString=dayTo+'.'+monthTo+'.'+yearTo
	}
	if(dateFrom){
		let dayFrom=dateFrom.substr(8,2)
		let monthFrom=dateFrom.substr(5,2)
		let yearFrom=dateFrom.substr(0,4)
		dateFromString=dayFrom+'.'+monthFrom
		if(dateTo && dateTo.substr(0,4)!=yearFrom){
			dateFromString+='.'+yearFrom
		}
	}
	return dateFromString+'-'+dateToString
}

function __adaptProjects(data){
	let result = []

	for(let item of data){
		result.push({
			id: item.id,
			name: item.name
		})
	}

	return result
}

function __adaptDepartments(data){
	let result = []

	for(let item of data){
		result.push({
			id: item.id,
			name: item.name
		})
	}

	return result
}

function __adaptLeaveDefinitions(data){
	let result = []

	for(let item of data){
		let leaveDef = {
			id: item.id,
			code: item.code,
			name: item.name,
			description: item.description,
			color: item.color,
			bgcolor: item.bgcolor,
			states: [],
			transitions: [],
		}

		for(let s of item.states){
			leaveDef.states.push({
				id: s.id,
				name: s.name,
				color: s.color,
				transitions: []
			})
		}

		for(let t of item.transitions){
			let stateTo = item.states.find(i=>i.id==t.state_to_id)

			let temp = {
				id: t.id,
				name: t.name,
				stateFromId: t.state_from_id,
				stateToId: t.state_to_id,
				stateToColor: stateTo? stateTo.color: null,
				stateToName: stateTo? stateTo.name: "Unknown state",
			}

			let state = leaveDef.states.find(i=>i.id==temp.stateFromId)

			if(state){
				state.transitions.push(temp)
			}
		}

		result.push(leaveDef)
	}

	return result
}

function adaptApiResponseLeavesData(data){
	let result = {
		projects: __adaptProjects(data.projects),
		departments: __adaptDepartments(data.departments),
		leaveDefinitions: __adaptLeaveDefinitions(data.leave_definitions)
	}

	return result
}

function adaptApiResponseLeaves(data){

	let result = {
		total: data.page_info? data.page_info.total: null,
		leaves: [],
		callendars:[],
		callendars_events:[]
	}

	for(let item of data.page){
		let tmp = {
			leaveId: item.id,
			workerId: item.worker.personal_data.id,
			workerFullname: item.worker.personal_data.last_name+" "+item.worker.personal_data.first_name,
			leaveTypeId: item.leave_definition_id,
			startDate: item.date_from,
			endDate: item.date_to,	
			leaveTime: dateRangeString(item.date_from,item.date_to),
			leaveStateId: item.leave_state_id,
			projectId: item.project_id,
			departmentId: item.department_id,
			permissions: {
				add: item.add,
				change: item.change,
				delete: item.delete,
				auditView: item.audit_view,
				detailsView: item.details_view,
				commentsView: item.comments_view,
				commentsAdd: item.comments_add,
				commentsChange: item.comments_change,
				commentsDelete: item.comments_delete,
			},
		}
		result.leaves.push(tmp)
	}
	result.callendars.push(data.callendars);
	result.callendars_events.push(data.callendars_events);

	return result
}

function adaptLeaveComments(comments){
	let result = []

	for(let item of comments){
		result.push({
			id: item.id,
			comment: item.comment,
			createdAt: item.created_at,
			createdBy: item.created_by,
			user: {
				id: item.created_by_user.id,
				fullName: `${item.created_by_user.last_name} ${item.created_by_user.first_name}`,
			}
		})
	}

	return result
}

function adaptLeaveAudit(data){
	let result = []

	let temp = null, item=null

	if(data.length>0){
		item = data[data.length-1]
		result.push({
			id: item.id,
			createdAt: item.created_at,
			created: true,
			createdBy: item.created_by
		})
	}

	for(let i=data.length-2;i>=0;i--){
		item = data[i]

		temp = {
			id: item.id,
			createdAt: item.created_at,
			created: false,
			createdBy: item.created_by
		}

		// Compare with previous item 
		let changesProvided = false
		let previous = data[i+1]
		
		if(previous.date_from!=item.date_from){
			changesProvided = true
			temp.dateFrom = {
				previous: previous.date_from,
				current: item.date_from
			}
		}

		if(previous.date_to!=item.date_to){
			changesProvided = true
			temp.dateTo = {
				previous: previous.date_to,
				current: item.date_to
			}
		}

		if(previous.note!=item.note){
			changesProvided = true
			temp.note = {
				previous: previous.note,
				current: item.note
			}
		}

		if(previous.leave_state.id!=item.leave_state.id){
			changesProvided = true
			temp.leaveState = {
				previous: previous.leave_state.name,
				current: item.leave_state.name
			}
		}
		
		if(changesProvided) result.push(temp)
	}

	return result
}


function adaptLeaveDetails(data){
	let adapted = {
		id: data.id,
		leaveState:{
			id: data.leave_state.id,
			name: data.leave_state.name,
		},
		workerId: data.worker.personal_data.id,
		workerFullName: data.worker.personal_data.last_name+" "+data.worker.personal_data.first_name,
		leaveType: {
			id: data.leave_definition?.id,
			name: data.leave_definition?.name,
		},
		project: data.project? {
			id: data.project.id,
			name: data.project.name,
		}: null,
		department: data.department? {
			id: data.department.id,
			name: data.department.name,
		}: null,
		startDate: data.date_from,
		endDate: data.date_to,
		permissions: {
			add: data.add,
			change: data.change,
			delete: data.delete,
			auditView: data.audit_view,
			commentsView: data.comments_view,
			commentsAdd: data.comments_add,
			commentsDelete: data.comments_delete,
			commentsChange: data.comments_change,
		},
		note: data.note,
		comments: [],
		audit: [],
	}

	return adapted
}

function adaptCanAddForAPIResponse(data){
	let result = {
		states: [],
		projects: [],
		departments: []
	}

	if(data.leave_states!=null){
		for(let item of data.leave_states){
			result.states.push({
				id: item.id,
				name: item.name
			})
		}
	}

	if(data.departments!=null){
		for(let item of data.departments){
			result.departments.push({
				id: item.id,
				name: item.name
			})
		}
	}

	if(data.projects!=null){
		for(let item of data.projects){
			result.projects.push({
				id: item.id,
				name: item.name
			})
		}
	}

	return result
}

function adaptWorkerProjects(data){
	let result = []

	for(let item of data){
		result.push({
			id: item.id,
			name: item.name
		})
	}

	return result
}


export default {
	namespaced: true,

	state: {
		leavesView: 'list',

		resizableColumnsCache: {
			columnsWidths: {
				leaveTime: 170,
				workerFullname: 220,
				leaveTypeName: 200,
				departmentName: 200,
				projectName: 200,
				statusLabel: 200,
			}
		},

		columnsVisibility: {
			0: true, //Time
			1: true, //Worker fullname
			2: true, //Leave type
			3: false, //Department
			4: false, //Project
			5: true, //Status
		},

		filters: {
			worker: null,
			workerFullName: null,
			month: null,
			status: null,
			project: -1,
			leaveType: -1,
			page: 1,
			perPage: 10,
			orderBy: {
				sortProperty: null,
				sortType: null
			}
		},

		saveType: 0,
	},

	getters: {
		columnsWidths: (state) => {
			return state.resizableColumnsCache.columnsWidths;
		},
		filters: (state) => {
			return structuredClone(state.filters);
		},
		columnsVisibility: (state) => {
			return structuredClone(state.columnsVisibility);
		},
		leavesView: (state) => {
            return state.leavesView;
        },
		saveType: (state) => {
            return state.saveType;
        },
	},
	mutations: {
		updateDesktopColumnsWidths(state, payload){
			for(let key in payload){
				state.resizableColumnsCache.columnsWidths[key] = payload[key];
			}
		},
		updateColumnsVisibility(state, payload){
			for(let key in payload){
				state.columnsVisibility[key] = payload[key];
			}
		},
		updateFilters(state, payload) {
			let fields = ["project", "month", "status", "leaveType", "perPage", "page", "orderBy", "worker", "workerFullName"];
			for (let i = 0; i < fields.length; i++) {
				if (typeof(payload[fields[i]]) !== 'undefined') {
					state.filters[fields[i]] = payload[fields[i]];
				}
			}
		},
		updateLeavesView(state, payload) {
            state.leavesView = payload
        },
		updateSaveType(state, payload) {
            state.saveType = payload
        },
	},
	actions: {
		async getLeavesData({ rootGetters }){
			let headers = rootGetters.standardRequestHeaders;
			let response = null;
			
			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/data/",
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptApiResponseLeavesData(response.data)
			
			return adapted
		},

		async getProjectsData({ rootGetters }){
			let headers = rootGetters.standardRequestHeaders;
			let response = null;
			
			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leaves/projects/",
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			// let adapted = adaptApiResponseLeavesData(response.data)
			
			return response
		},

		async getLeaveTypes({ rootGetters }){
			let headers = rootGetters.standardRequestHeaders;
			let response = null;
			
			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/admin/leave-definitions",
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			// let adapted = adaptApiResponseLeaveTypes(response.data)
			
			// No need to adapt
			return response.data
		},
		async getProjects({ rootGetters }){
			let headers = rootGetters.standardRequestHeaders;
			let response = null;
			
			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves/projects/",
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response.data
		},
		async getLeaves({ commit, rootGetters }, payload){
			let requiredFields = [
				"startDate", "endDate", "page", "perPage"
			];
			let optionalFields = [
				["page", 1],
				["perPage", 10],
				["status", undefined],
				["project", undefined],
				["leaveType", undefined],
				["worker", undefined],
				// ["orderBy", undefined]
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			let d = tmp.validatedData;
			let params = {}
			let newFilters = {}

			params.date_from = d.startDate,
			params.date_to = d.endDate

			params.page = d.page
			params.per_page = d.perPage

			if(d.page!=-1) {
				newFilters.perPage = d.perPage
				newFilters.page = d.page
			}
			
			if(typeof(d.worker) !== 'undefined'){
				newFilters.worker = params.worker_ids = d.worker
			}
			else{
				newFilters.worker=null
			}

			if(typeof(d.project) !== 'undefined' && d.project!==-1){
				newFilters.project = params.project_ids = d.project
			}
			else{
				newFilters.project=-1
			}

			if(typeof(d.leaveType) !== 'undefined'){
				newFilters.leaveType = params.leave_definition_ids = d.leaveType
			}
			else{
				newFilters.leaveType = -1
			}

			if(typeof(d.status) !== 'undefined'){
				newFilters.status = d.status
				params.leave_state_ids = d.status.toString()
			}
			else{
				newFilters.status = null
			}

			if(typeof(d.orderBy) !== 'undefined'){
				params.order_by = d.orderBy

				let arr = d.orderBy.split('.')
				newFilters.orderBy = {
					sortProperty: arr[0],
					sortType: arr[1]
				}
			}

			commit("updateFilters", newFilters);

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leaves/",
					headers: headers,
					params: params
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptApiResponseLeaves(response.data)

			return adapted
		},
		async deleteLeave({ rootGetters }, payload){
			let requiredFields = ["leaveId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
			
			return response
		},
		async getWorkerProjects({ rootGetters }, payload){
			let requiredFields = ["workerId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves/worker/"+d.workerId+"/projects/",
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptWorkerProjects(response.data)
			
			return adapted
		},
		async getLeaveComments({ rootGetters }, payload){
			let requiredFields = ["leaveId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId+"/comments/",
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptLeaveComments(response.data)
			
			return adapted
		},
		async getLeaveAudit({ rootGetters }, payload){
			let requiredFields = ["leaveId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId+"/audit/",
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptLeaveAudit(response.data)
			
			return adapted
		},
		async getLeaveDetails({ rootGetters }, payload){
			let requiredFields = ["leaveId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptLeaveDetails(response.data)
			
			return adapted
		},
		async canAddForLeave({ rootGetters }, payload){
			let requiredFields = ["leaveTypeId"];

			let optionalFields = [
				["departmentId", null],
				["projectId", null],
				["stateId", null],
			]

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let params = {}
			
			if(d.stateId!==null){
				params.leave_state_id = d.stateId
			}
			if(d.departmentId!==null){
				params.department_id = d.departmentId
			}
			if(d.projectId!==null){
				params.project_id = d.projectId
			}
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leaves/can-add-for/"+d.leaveTypeId,
					headers: headers,
					params: params
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptCanAddForAPIResponse(response.data)
			
			return adapted
		},
		async editLeave({ rootGetters }, payload){
			let requiredFields = ["leaveId"];

			let optionalFields = [
				["dateFrom", null],
				["dateTo", null],
				["note", null],
			]

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let data = {}
			if(d.dateFrom!=null){
				data.date_from = d.dateFrom
			}
			if(d.dateTo!=null){
				data.date_to = d.dateTo
			}
			if(d.note!=null){
				data.note = d.note
			}
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async addLeave({ rootGetters }, payload){
			let requiredFields = ["state", "leaveTypeId", "dateFrom", "dateTo", "workerId"];

			let optionalFields = [
				["note", null],
				["projectId", null],
				["departmentId", null],
			]
			
			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let data = {
				worker_id: d.workerId,
				leave_state_id: d.state,
				leave_definition_id: d.leaveTypeId,
				date_from: d.dateFrom,
				date_to: d.dateTo,
			}

			if(d.note!=null){
				data.note = d.note
			}

			if(d.projectId!=null){
				data.project_id = d.projectId 
			}

			else if(d.departmentId!=null){
				data.department_id = d.departmentId
			}
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;
			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leaves/",
					headers: headers,
					data: data,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async changeState({ rootGetters }, payload){
			let requiredFields = ["leaveId", "leaveStateId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId+"/change-state/",
					headers: headers,
					data: {
						leave_state_id: d.leaveStateId
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async addComment({ rootGetters }, payload){
			let requiredFields = ["leaveId", "comment"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId+"/comments/",
					headers: headers,
					data: {
						comment: d.comment
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async editComment({ rootGetters }, payload){
			let requiredFields = ["leaveId", "commentId", "comment"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId+"/comment/"+d.commentId,
					headers: headers,
					data: {
						comment: d.comment
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async deleteComment({ rootGetters }, payload){
			let requiredFields = ["leaveId", "commentId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;			

			try {
				response = await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/leaves-v2/leave/"+d.leaveId+"/comment/"+d.commentId,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},

	}
}