<template>
	<v-app style="max-width: 100%; overflow-x: clip;">
		<Navbar
			ref="Navbar"
			v-show="navbarVisible"
			:title="title" 
			:open="false"
			:goBackLink="goBackLink"
			:goBackDisabled="goBackDisabled"
			@set-state="setState"
			@display-error="displayError"
			@go-back-clicked="tryGoBack()"
			@set-timesheets-view="setTimesheetsView"
			@toogle-navigation-drawer="setLeaveHistoryPanelDrawer()"
			@set-leaves-view="setLeavesView"
			@set-leave-management-view="setLeaveManagementView"
			@set-record-details-view="setRecordDetailsView"
			@logout="onLogout"
			@tenant-selected="onTenantSelect"
			@history-panel-open="leaveManagement.historyPanelDrawer=true"
			@delete-leave-dialog-open="leaveManagement.deleteDialog=true"
		></Navbar>

		<v-layout v-if="state == 'ERROR'" class="content-middler" column justify-center align-center>
			<v-icon size="100">mdi-emoticon-sad-outline</v-icon>
			<div class="title"
			style="width:90%; text-align: center; margin-top:10px;">{{ globalErrorMessage }}</div>
		</v-layout>

		<!-- Loading overlayer (covers the screen with a shadow and displays a spinner) -->
		<v-layout v-if="state == 'LOADING_OVERLAYER'"
			class="loading-overlayer"
			column
			justify-center
			align-center>
			<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
			<div class="title" style="margin-top:10px">
				{{ loadingOverlayerMessage }}
			</div>
		</v-layout>

		<!-- Displaying error snackbar -->
		<v-snackbar
			v-model="snackbar.visible"
			:color="snackbar.color"
		>
			{{ snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar.visible = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<v-main>
			<router-view v-bind:style="{ display: routerViewDisplay}"
				@set-title="setTitle($event)" 
				@getGoBackLink="getGoBackLink($event)"
				@go-back-clicked-taken="goBackClicked=false"
				@disable-go-back="disableGoBack($event)"
				@display-error="displayError"
				@display-success="displaySuccess"
				@set-state="setState"
				@loggedIn="onLogin"
				@logout="onLogout"
				@update-locale="onUpdateLocale"
				@refresh-timer="onRefreshTimer"
				@app-outdated="onAppOutdated"
				@set-leave-management-view="setLeaveManagementView"
				@set-record-details-view="setRecordDetailsView"
				@history-panel-close="leaveManagement.historyPanelDrawer=false"
				@delete-leave-dialog-close="leaveManagement.deleteDialog=false"
				:goBackClicked="goBackClicked"
				:timer="timer"
				:timesheetsView="timesheets.selectedView"
				:leavesView="leaves.selectedView"
				:leaveManagementOptions="leaveManagement"
				:recordManagementOptions="recordDetailsManagement"
				:historyDrawer="historyDrawerBoolean"
				@update:historyDrawerCard="updateHistoryDrawer"
				@update:trueHistoryDrawerCard="trueHistoryDrawerCard" 

			></router-view>
		</v-main>

		<Timer
			ref="Timer"
			:permissions="permissions"
			:workerId="workerId"
			@display-error="displayError"
			@app-outdated="onAppOutdated"
		></Timer>

		<!-- <v-bottom-navigation>
			<v-btn value="recent">
			<span>Recent</span>

			<v-icon>mdi-history</v-icon>
			</v-btn>
		</v-bottom-navigation> -->

		<v-dialog
			v-model="appOutdatedDialog.model"
			width="350"
		>
			<v-card>
				<v-card-title>
					{{ $t("applicationNotUpToDate") }}
				</v-card-title>

				<v-divider style="margin-bottom: 20px"></v-divider>

				<v-card-text>
					{{ $t("yourApplicationIsOutdatedAndNotCompatibleWithRequetedApi") }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						@click="appOutdatedDialog.model = false"
					>
						{{ $t("ignore") }}
					</v-btn>

					<v-btn
						color="primary"
						text
						@click="updateAppVersion()"
					>
						{{ $t("reload") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- <v-snackbar
			v-model="updateAppSnackbar.model"
			:timeout="-1"
		>
			{{ $t('newAppVersionAvailable') }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="#FBC02D"
					text
					v-bind="attrs"
					@click="updateAppVersion()"
				>{{ $t('reload') }}</v-btn>
			</template>
		</v-snackbar> -->
	</v-app>
</template>

<script>
// import Navbar from './components/Navbar.vue'
const Navbar = () => import('./components/Navbar.vue');
import Timer from './components/Timer.vue';
import appConfig from '@/app_config'
import axios from 'axios'
import jwt_decode from 'jwt-decode'


const NAVBAR_EXCLUDED_FOR = [
	"login", "resetPassword", "newPassword", "sendLink", "firstLogging", "chooseTenant", "switchingTenantSplashScreen"
];

export default {
	
	name: 'App',

	components: {
		Navbar,
		Timer
	},

	data: () => ({
		historyDrawerPin: false,
		historyDrawerBoolean: false,
		versionCheckInterval: null,

		title: "",
		navbarVisible: false,
		goBackLink: "",
		goBackDisabled: false,
		goBackClicked: false,
		user: "",
		usermail: "",
		globalErrorMessage: null,
		loadingOverlayerMessage: null,
		routerViewDisplay: "block",
		state: "DEFAULT",
		tenants: null,
		tenant: null,
		snackbar: {
			visible: false,
			color: "primary",
			message: ""
		},

		timer: {
			active: false,
			startAt: null,
			stopAt: null,
			counter: 0,
			projectId: null,
			ref: null
		},

		timesheets: {
			selectedView: 'hours'
		},
		leaves: {
			selectedView: 'list',
		},
		leaveManagement: {
			selectedView: 'edit',
			historyPanelDrawer: false,
			deleteDialog: false,
		},
		recordDetailsManagement: {
			selectedView: 'edit',
			historyPanelDrawer: false,
			deleteDialog: false,
		},
		updateAppSnackbar: {
			model: false,
			registration: null
		},
		appOutdatedDialog: {
			model: false
		},

		isLoggedInInterval: null,
	}),
	computed: {
		permissions(){
			let ct = this.$store.getters["tenants/currentTenant"];
			if(ct != null){
				return ct.permissions.map((obj) => obj.codename);
			}
			return null;
		},
		workerId(){
			let ct = this.$store.getters["tenants/currentTenant"];
			if(ct != null && ct.worker == true && ct.personalData != null){
				return ct.personalData.id;
			}
			return null;
		},
		isLoggedIn() {
			return this.$store.getters.isLoggedIn;
		},
	},
	created() {
		// var that = this;
		// document.addEventListener("swUpdated", function(e){
		// 	console.log(">>> SW UPDATED!!");
		// 	that.updateAppSnackbar.model = true;
		// 	that.updateAppSnackbar.registration = e.detail.registration;
		// }, { once: true });
	},
	mounted() {
		this.historyDrawerBoolean = this.$store.state.historyDrawerPin;
		// if(NAVBAR_EXCLUDED_FOR.includes(this.$route.path)){
		//   this.navbarVisible = false;
		// }
		// else {
		//   this.navbarVisible = true;
		// }
		this.navbarVisible = false;
		if(this.$route.name != null){
			for(let i=0;i<NAVBAR_EXCLUDED_FOR.length;i++){
				if(this.$route.name == NAVBAR_EXCLUDED_FOR[i]){
					this.navbarVisible=false
					break;
				}
			}
		}
		var that = this;
		setTimeout(function(){
			that.$refs.Timer.getTimerFromAPI();
		}, 200);

		this.versionCheckInterval = setInterval(this.onVersionCheck, 60000);

		if(this.$store.getters["isLoggedIn"]){
			this.isLoggedInInterval = setInterval(this.isLoggedInCheck, 1000);
		}
	},
	beforeMount(){
		// locale (set to English by default)
		this.$i18n.locale = 'en';
		if (this.$cookies) {
			let language = this.$cookies.get('language');
				if(language != null && language !== 'undefined'){
					this.$root.$i18n.locale = language;
			}
		}    
	},
	methods: {
		trueHistoryDrawerCard(){
			this.$store.commit({
				type: 'updateHistoryDrawlerPin',
			})
		},
		updateHistoryDrawer(bool){
			this.historyDrawerBoolean = !bool;
		},
		async onVersionCheck(){
			let headers = this.$store.getters["standardRequestHeaders"];

			let result = null;
			try {
				result = await axios({
					method: "GET",
					url: appConfig.getApiUrl(null) + "/api/v1/dedicated-app-expected-version/",
					headers: headers
				});
			} catch(error){
				// Avoiding interference with user experience
				// If it doesn't work, just ignore it
				// Evemtually user will get the popup one way or another
				return;
			}
			
			if(result.data.slice(0, 8) > appConfig.appVersion.slice(0, 8)){
				this.updateAppSnackbar.model = true;
			}
		},
		isLoggedInCheck(){
			let accessToken = this.$store.getters["accessToken"];
			if(accessToken != '' && accessToken != null && typeof(accessToken) !== 'undefined'){
                let decoded;
                try{
                    decoded = jwt_decode(accessToken);
                } catch(error){
                    return false;
                }
                let exp = decoded.exp;
                if ((exp - (Date.now() / 1000)) < 0) {
                    this.onLogout()
                }
            }
            else{
                this.onLogout()
            }
		},

		onUpdateLocale(newLocale){
			this.$root.$i18n.locale = newLocale;
			this.$cookies.set("language", newLocale);
		},
		onAppOutdated(){
			this.updateAppSnackbar.model = true;
			this.appOutdatedDialog.model = true;
		},
		setTitle(title){
			this.title=title
		},
		getGoBackLink(link){
			this.goBackLink=link
		},
		tryGoBack(){
			this.goBackClicked=true
		},
		disableGoBack(value){
			this.goBackDisabled=value
		},
		setTimesheetsView(view){
			this.timesheets.selectedView=view
		},
		setLeavesView(view){
			this.leaves.selectedView = view
		},
		setLeaveManagementView(view){
			this.leaveManagement.selectedView=view
			if(this.$refs.Navbar){
				this.$refs.Navbar.leaveManagementView = view
			}
		},
		setRecordDetailsView(view){
			this.recordDetailsManagement.selectedView=view
			if (view =='history'){
				this.recordDetailsManagement.historyPanelDrawer = true;
			}
			else {
				this.recordDetailsManagement.historyPanelDrawer = false;
			}
			if(this.$refs.Navbar){
				this.$refs.Navbar.recordDetailsManagementView = view
			}
		},
		setLeaveHistoryPanelDrawer(){
			if (this.historyDrawerBoolean == false) {
				this.historyDrawerBoolean = true;

			} else
			this.historyDrawerBoolean = false;
		},
		onLogin(){
			clearInterval(this.isLoggedInInterval)
			this.isLoggedInInterval = setInterval(this.isLoggedInCheck, 1000);
			
			let currentTenant = this.$store.getters["tenants/currentTenant"];
			if(currentTenant == null){
				return;
			}

			var that = this;
			this.$nextTick(function(){
				that.$refs.Navbar.reload();
				that.$refs.Navbar.redirectDefault();
				that.$refs.Timer.getTimerFromAPI();
			});
		},
		onLogout(){
			clearInterval(this.isLoggedInInterval)

			this.$refs.Timer.clear();
			localStorage.clear();
			this.$store.dispatch("logout");

			if(this.$route.name != 'login')
				this.$router.push("/login");
		},
		onTenantSelect(){
			var that = this;
			this.$nextTick(function(){
				that.$refs.Timer.clear();
				that.$refs.Timer.getTimerFromAPI();
			});
		},
		async onRefreshTimer(){
			this.$refs.Timer.clear();
			await this.$refs.Timer.getTimerFromAPI();
		},
		displayError(errorMessage){
			this.snackbar.color="error"
			this.snackbar.message=errorMessage
			this.snackbar.visible=true
		},
		displaySuccess(message){
			this.snackbar.color="success"
			this.snackbar.message=message
			this.snackbar.visible=true
		},
		removeErrorDialog(dialogId) {
			for (var i = 0; i < this.errorDialogs.length; i++) {
				if (this.errorDialogs[i].id == dialogId) {
					setTimeout(() => {
						this.errorDialogs.splice(i, 1);
					}, 200);
					break;
				}
			}
		},
		setState(newState, additionalParams = null) {
			this.state = newState.toUpperCase();
			var isDefault = false;

			switch (this.state) {
				// case "LOADING":
				//     this.loadingMessage =
				//         typeof additionalParams === "undefined" || additionalParams == null
				//             ? this.$t('loading')
				//             : additionalParams;
				//     break;
				case "LOADING_OVERLAYER":
					this.loadingOverlayerMessage =
						typeof additionalParams === "undefined" || additionalParams == null
							? this.$t('loading')
							: additionalParams;
					isDefault = true;
					break;
				// case "PROGRESS_OVERLAYER":
				//     if (
				//         typeof additionalParams === "undefined" ||
				//         additionalParams == null
				//     ) {
				//         this.progressOverlayerMessage = this.$t('loading;')
				//         this.progressBar = 0;
				//     } else {
				//         if (Array.isArray(additionalParams)) {
				//             this.progressOverlayerMessage = additionalParams[0];
				//             this.progressBar = additionalParams[1];
				//         } else if (typeof additionalParams === "object") {
				//             this.progressOverlayerMessage = additionalParams.message;
				//             this.progressBar = additionalParams.progress;
				//         } else {
				//             this.progressOverlayerMessage = additionalParams;
				//             this.progressBar = 0;
				//         }
				//     }
				//     isDefault = true;
				//     break;
				case "ERROR":
					this.globalErrorMessage =
						typeof additionalParams === "undefined" || additionalParams == null
							? this.$t('errorOccured')
							: additionalParams;
					break;
				default:
					this.state = "DEFAULT";
					isDefault = true;
			}

			if (isDefault) this.routerViewDisplay = "block";
			else this.routerViewDisplay = "none";
		},
		
		updateAppVersion(){
			this.updateAppSnackbar.model = false;
			window.location.reload(true);
		}
	},
	watch: {
		$route: function(){ 
			this.goBackDisabled = false

			if(NAVBAR_EXCLUDED_FOR.includes(this.$route.name)){
				this.navbarVisible = false;
			}
			else {
				this.navbarVisible = true;
				if(typeof(this.$refs.Navbar) !== 'undefined'){
					this.$refs.Navbar.setSelectedMenuItem();
				}
			}
		},
	}
};
</script>

<style>

html, body{
	height: 100%;
}

/* .main{
	margin-top: 35px;
} */

.main{
	padding-top: 70px;
}

.loading-overlayer {
	width: 100%;
	height: 100%;
	z-index: 300;
	color: #fff;
	position: fixed;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.64);
}

</style>

<i18n>
{
	"en": {
		"loading": "Loading...",
		"errorOccured": "An unknown error occured. That's all we know.",
		"newAppVersionAvailable": "New app version available",
		"reload": "Reload",
		"applicationNotUpToDate": "Outdated version",
		"yourApplicationIsOutdatedAndNotCompatibleWithRequetedApi": "Your application version is outdated and not compatible with API you're trying to use. Please reload the application to avoid these errors in the future.",
		"ignore": "Ignore"
	},
	"pl": {
		"loading": "Ładowanie...",
		"errorOccured": "Wystąpił błąd. Tylko tyle wiemy.",
		"newAppVersionAvailable": "Nowa wersja aplikacji jest dostępna",
		"reload": "Odśwież",
		"applicationNotUpToDate": "Przestarzała wersja",
		"yourApplicationIsOutdatedAndNotCompatibleWithRequetedApi": "Wersja aplikacji, której używasz jest przestarzała i niekompatybilna z API, którego próbowałeś użyć. Przeładuj aplikację, aby uniknąć tego błędu w przyszłości.",
		"ignore": "Ignoruj"
	},
	"de": {
		"loading": "Herunterladen...",
		"errorOccured": "Ein Fehler ist aufgetreten. Das ist alles, was wir wissen.",
		"newAppVersionAvailable": "Neue App-Version verfügbar",
		"reload": "Neu laden",
		"applicationNotUpToDate": "Veraltete Version",
		"yourApplicationIsOutdatedAndNotCompatibleWithRequetedApi": "Ihre Anwendungsversion ist veraltet und nicht mit der API kompatibel, die Sie zu verwenden versuchen. Bitte laden Sie die Anwendung neu, um diese Fehler in Zukunft zu vermeiden.",
		"ignore": "Ignorieren"
	}
}
</i18n>
