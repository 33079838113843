import structuredClone from '@ungap/structured-clone';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import axios from 'axios';
import appConfig from '@/app_config';

// Temporary solution, until we have a on_change_language handle
// TODO - fix it later
const CACHE_VALIDITY = 1; // 1 * 60 * 60 * 1000; // 1 hour

// ########
// ADAPTERS
// ########

function __adaptExportFormatsResponse(exportFormats){
	let format;
	let response = [];
	for (let i = 0; i < exportFormats.length; i++){
		format = exportFormats[i];
		response.push({
			id: format.id,
			name: format.name,
			code: format.code,
			isGlobal: format.is_global
		});
	}
	return response;
}

function __adaptExportFormatConfigurationResponse(formatId, configurationResponse){
	let adapted = {
		formatId: formatId,
		exportedFileType: configurationResponse.exported_file_type,
		defaultFields: {
			workers: null,
			projects: null,
			timePeriod: null
		},
		templateOptions: []
	};

	let target = adapted.defaultFields;
	// Adapt default fields - workers
	let s = configurationResponse.default_fields.workers;
	target.workers = {
		allow: s.allow,
		required: s.required
	};

	// Adapt default fields - projects
	s = configurationResponse.default_fields.projects;
	target.projects = {
		allow: s.allow,
		required: s.required,
		multiple: s.multiple
	};

	// Adapt default fields - time period
	s = configurationResponse.default_fields.time_period;
	target.timePeriod = {
		allow: s.allow,
		required: s.required,
		daily: {
			allow: s.daily.allow,
			min: (s.daily.min != null) ? new Date(s.daily.min) : null,
			max: (s.daily.max != null) ? new Date(s.daily.max) : null
		},
		weekly: {
			allow: s.weekly.allow,
			min: (s.weekly.min != null) ? new Date(s.weekly.min) : null,
			max: (s.weekly.max != null) ? new Date(s.weekly.max) : null,
			firstWeekDay: s.weekly.first_week_day
		},
		monthly: {
			allow: s.monthly.allow,
			min: (s.monthly.min != null) ? new Date(s.monthly.min) : null,
			max: (s.monthly.max != null) ? new Date(s.monthly.max) : null,
			firstMonthDay: s.monthly.first_month_day
		},
		yearly: {
			allow: s.yearly.allow,
			min: (s.yearly.min != null) ? new Date(s.yearly.min) : null,
			max: (s.yearly.max != null) ? new Date(s.yearly.max) : null,
			firstYearDay: {
				month: s.yearly.first_year_day[0],
				day: s.yearly.first_year_day[1]
			}
		},
		custom: {
			allow: s.custom.allow,
			min: (s.custom.min != null) ? new Date(s.custom.min) : null,
			max: (s.custom.max != null) ? new Date(s.custom.max) : null
		}
	};

	// Adapt template options
	target = adapted.templateOptions;
	if(configurationResponse.template_options != null){
		for(let key in configurationResponse.template_options){
			s = configurationResponse.template_options[key];
			target.push({
				name: key,
				type: s.type,
				label: s.label,
				default: (typeof(s.default) !== 'undefined') ? s.default : undefined
			});
		}
	}

	return adapted;
}

// ###############
// End of ADAPTERS
// ###############

export default {
	namespaced: true,

	state: {
		requestExportFormatsId: 0,
		requestExportFormatConfigurationId: 0,

		exportFormats: null, // {
		// 	cachedAt: {Date},

		// 	// Dictionary key as format ID
		// 	items: [
		// 		{
		// 			id: {Number},
		// 			name: {String},
		// 			code: {String},
		// 			isGlobal: {Boolean}
		// 		}
		// 	]
		// },

		// Contains all cached configs
		// Config cache validity period is 1h
		// After 1 hour format will require redownload to display retrieve the config
		exportsConfigurations: {
			// // Dictionary key as format ID
			// {Number}: {
			// 	cachedAt: {Date},
			//	lang: {String},
			// 	configuration: {
			//		formatId: {Number},
			// 		defaultFields: {
			// 			workers: {
			// 				allow: {Boolean},
			// 				required: {Boolean}
			// 			},
			// 			projects: {
			// 				allow: {Boolean},
			// 				required: {Boolean}
			// 			},
			// 			timePeriod: {
			// 				allow: {Boolean},
			// 				required: {Boolean},

			// 				daily: {
			// 					allow: {Boolean},
			// 					min: {Date},
			// 					max: {Date}
			// 				},
							
			// 				weekly: {
			// 					allow: {Boolean},
			// 					min: {Date},
			// 					max: {Date},

			// 					firstWeekDay: {Number} // 0 - sunday, 1 - monday, 2 - tuesday, and so on
			// 				},

			// 				monthly: {
			// 					allow: {Boolean},
			// 					min: {Date},
			// 					max: {Date},

			// 					firstMonthDay: {Number}
			// 				},

			// 				yearly: {
			// 					allow: {Boolean},
			// 					min: {Date},
			// 					max: {Date},

			// 					firstYearDay: [ {Number}, {Number} ]
			// 				},

			// 				custom: {
			// 					allow: {Boolean},
			// 					min: {Date},
			// 					max: {Date}
			// 				}
			// 			}
			// 		},

			// 		templateOptions: [
			// 			{
			// 				name: {String}, // treat it like a form field name (identifier of the field)
			// 				type: {Number},
			// 				label: {String}, // Translated label
			// 				[default]: {Any}, // will be assigned only if default value was configured

			// 				// if type == 1 || type == 2
			// 				choices: [
			// 					{
			// 						id: {Number},
			// 						name: {String}
			// 					}
			// 				]
			// 			}
			// 		]
			// 	}
			//}
		}
	},

	getters: {

	},

	mutations: {
		incrementRequestExportFormatsId(state) {
			state.requestExportFormatsId++;
		},
		incrementRequestExportFormatConfigurationId(state) {
			state.requestExportFormatConfigurationId++;
		},

		updateExportFormats(state, payload){
			state.exportFormats = {
				cachedAt: new Date(),
				items: payload
			};
		},

		updateExportFormatConfiguration(state, payload){
			let formatId = payload.configuration.formatId;

			state.exportsConfigurations[formatId] = {
				cachedAt: new Date(),
				lang: payload.lang,
				configuration: payload.configuration
			};
		}
	},

	actions: {
		async getExportFormats({state, commit, rootGetters}){
			// Attempting to use cache
			if(state.exportFormats != null){
				let now = new Date();
				let timeDiff = (now - state.exportFormats.cachedAt);

				if(timeDiff < CACHE_VALIDITY){
					return structuredClone(state.exportFormats.items);
				}
			}

			commit('incrementRequestExportFormatConfigurationId');
			let requestId = state.requestExportFormatsId;

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/exports/",
					headers: headers
				});
			} catch (error) {
				if(requestId == state.requestExportFormatsId){
					throw {
						errorType: "request",
						error: error
					}
				}
				else{
					// Request cancelled
					throw {
						errorType: "request_cancelled",
						error: "Request has been cancelled"
					};
				}
			}

			if(requestId == state.requestExportFormatsId){
				let adapted = __adaptExportFormatsResponse(response.data);

				commit('updateExportFormats', adapted);

				return structuredClone(adapted);
			}
			else {
				// Request cancelled
				throw {
					errorType: "request_cancelled",
					error: "Request has been cancelled"
				};
			}
		},

		async getExportFormatConfiguration({state, commit, rootGetters}, payload){
			let requiredFields = [
				"formatId",
				"formatCode",
				"lang"
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, null);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			// Atempting to use cache
			if(typeof(state.exportsConfigurations[d.formatId]) !== 'undefined'){
				let cachedData = state.exportsConfigurations[d.formatId];
				let now = new Date();

				if(d.lang == cachedData.lang && now - cachedData.cachedAt < CACHE_VALIDITY){
					return structuredClone(cachedData.configuration);
				}
			}

			// Failed to use cache, retrieving configuration from the server
			commit('incrementRequestExportFormatConfigurationId');
			let requestId = state.requestExportFormatConfigurationId;

			let headers = rootGetters.standardRequestHeaders;
			headers["Accept-Language"] = d.lang;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/export/" + d.formatCode,
					headers: headers,
					params: {
						lang: d.lang
					}
				});
			} catch(error){
				if(requestId == state.requestExportFormatConfigurationId){
					throw {
						errorType: "request",
						error: error
					}
				}
				else{
					// Request cancelled
					throw {
						errorType: "request_cancelled",
						error: "Request has been cancelled"
					};
				}
			}

			if(requestId == state.requestExportFormatConfigurationId){
				let adapted = __adaptExportFormatConfigurationResponse(d.formatId, response.data);

				commit('updateExportFormatConfiguration', {
					lang: d.lang,
					configuration: adapted
				});

				return structuredClone(adapted);
			}
			else {
				// Request cancelled
				throw {
					errorType: "request_cancelled",
					error: "Request has been cancelled"
				};
			}
		},

		async requestExport({rootGetters}, payload){
			let requiredFields = [
				"requestData",
				"formatCode",
				"lang"
			];
			let optionalFields = [
				["responseBlob", true]
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let responseType = undefined;
			if(d.responseBlob){
				responseType = "blob";
			}

			let headers = rootGetters.standardRequestHeaders;
			headers["Accept-Language"] = d.lang;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/export/" + d.formatCode,
					headers: headers,
					responseType: responseType,
					data: d.requestData
				});
			} catch(error){
				throw {
					errorType: "request",
					error: error
				};
			}

			return response;
		}
	}
}