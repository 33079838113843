// Useful extension for this file: #region folding for VS Code

import axios from 'axios';
import appConfig from '@/app_config';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import structuredClone from '@ungap/structured-clone';


function countShiftEndTime(startTime, duration){
	if(startTime){
		let date=new Date()
		date.setHours(startTime.substr(0,2), startTime.substr(3,2),startTime.substr(6,2),0)
		date.setMinutes(date.getMinutes()+duration)

		let hours = date.getHours()
		let minutes = date.getMinutes()

		if(hours<10){
			hours="0"+hours
		}
		if(minutes<10){
			minutes="0"+minutes
		}	
		return hours+":"+minutes
		}
	return null
}

// #########################
// Payload Adapters
// #########################

function adaptTagsAPIResponse(data){
	let result = []

	for(let item of data){
		result.push({
			id: item.id,
			name: item.choice_name,
			color: item.color
		})
	}

	return result
}

function adaptShiftDefinitionsAPIResponse(data){
	let result = []
	let temp

	for(let item of data){
		temp = {
			id: item.id,
			name: item.name,
			shiftDefinitions: []
		}

		for(let shift of item.shift_definitions){
			temp.shiftDefinitions.push({
				id: shift.id,
				name: shift.name,
				description: shift.description,
				startTime: shift.start_time? shift.start_time.substr(0, 5): null,
				endTime: countShiftEndTime(shift.start_time, shift.duration),
				color: shift.color
			})
		}

		result.push(temp)
	}

	return result
}

function adaptShifts(shifts){
	let result = []

	for(let shift of shifts){
		result.push({
			id: shift.id,
			day: shift.day,
			shiftId: shift.shift_definition_id,
			name: shift.name,
			color: shift.color,
			assignedWorkers: shift.workers_count,
			workerDemand: shift.worker_demand,
			startTime: shift.start_time? shift.start_time.substr(0, 5): null,
			endTime: countShiftEndTime(shift.start_time, shift.duration),
			recordLock: shift.record_lock,
		})
	}

	return result
}

function adaptProjectDailyShiftsAPIResponse(data){
	let result = {}

	for(let item of data.days){
		result[item.day] = adaptShifts(item.day_shifts)
	}

	return result
}

function adaptDayShiftDetails(data){
	let temp = {
		id: data.id,
		shiftId: data.shift_definition? data.shift_definition.id: null,
		shiftDefinition: data.shift_definition? {
			name: data.shift_definition.name,
			color: data.shift_definition.color? data.shift_definition.color: "#000000",
			startTime: data.shift_definition.start_time? data.shift_definition.start_time.substr(0, 5): null,
			endTime: countShiftEndTime(data.shift_definition.start_time, data.shift_definition.duration),
			startTolerance: data.shift_definition.start_tolerance,
			endTolerance: data.shift_definition.end_tolerance,
			startRoundingType: data.shift_definition.start_rounding_type,
			startRounding: data.shift_definition.start_rounding,
			startEarlier: data.shift_definition.entry_earlier,
			startLate: data.shift_definition.entry_late,
			endRoundingType: data.shift_definition.end_rounding_type,
			endRounding: data.shift_definition.end_rounding,
			endEarlier: data.shift_definition.exit_earlier,
			endLate: data.shift_definition.exit_late,
			workerDemand: data.shift_definition.worker_demand,
		}: null,
		name: data.name,
		color: data.color? data.color: "#000000",
		colorLock: data.color_lock,
		recordLock: data.record_lock,
		startTime: data.start_time? data.start_time.substr(0, 5): null,
		startTimeLock: data.start_time_lock,
		endTime: countShiftEndTime(data.start_time, data.duration),
		endTimeLock: data.duration_lock,
		startTolerance: data.start_tolerance,
		startToleranceLock: data.start_tolerance_lock,
		endTolerance: data.end_tolerance,
		endToleranceLock: data.end_tolerance_lock,
		startRoundingType: data.start_rounding_type,
		startRoundingTypeLock: data.start_rounding_type_lock,
		startRounding: data.start_rounding,
		startRoundingLock: data.start_rounding_lock,
		startEarlier: data.entry_earlier,
		startEarlierLock: data.entry_earlier_lock,
		startLate: data.entry_late,
		startLateLock: data.entry_late_lock,
		endRoundingType: data.end_rounding_type,
		endRoundingTypeLock: data.end_rounding_type_lock,
		endRounding: data.end_rounding,
		endRoundingLock: data.end_rounding_lock,
		endEarlier: data.exit_earlier,
		endEarlierLock: data.exit_earlier_lock,
		endLate: data.exit_late,
		endLateLock: data.exit_late_lock,
		assignedWorkers: data.workers_count,
		workerDemand: data.worker_demand,
		workerDemandLock: data.worker_demand_lock,
	}

	return temp
}

function adaptLeaves(leaves){
	let result = []

	for(let item of leaves){
		result.push({
			id: item.id,
			code: item.leave_type.code,
			name: item.leave_type.name,
			color: item.leave_type.color,
			bgColor: item.leave_type.bgcolor,
		})
	}

	return result
}

function adaptTags(data, tagsDictionary){
	let result = []
	let temp

	for(let item of data){
		temp = tagsDictionary.find(i=> i.id==item.tag)
		if(temp){
			result.push({
				id: item.id,
				tagId: item.tag,
				code: temp.code,
				name: temp.name,
				color: temp.color,
				bgColor: temp.bgcolor,
			})
		}
	}

	return result
}

function adaptWorkers(data, tagsDictionary){
	let result = []
	let temp
	let shiftArr

	for(let item of data){
		temp = {
			id: item.id,
			fullname: item.personal_data.full_name,
			initials: item.personal_data.last_name[0] + item.personal_data.first_name[0],
			shiftsLabel: "",
			tags: adaptTags(item.tags, tagsDictionary),
			leaves: adaptLeaves(item.leaves),
			removed: item.removed? true: false,
		}

		shiftArr=[]
		for(let s of item.shifts){
			shiftArr.push(s.name)
		}

		temp.shiftsLabel=shiftArr.join(', ')

		result.push(temp)
	}

	return result
}

function adaptDayShiftWorkers(data, tagsDictionary){

	let workersAssigned = adaptWorkers(data.workers_assigned, tagsDictionary)
	let workersOverride = adaptWorkers(data.workers_override, tagsDictionary)

	for(let item of workersAssigned){
		item.assignedToDefinition = true
	}

	for(let item of workersOverride){
		item.assignedToDefinition = false
	}

	let result = workersAssigned.concat(workersOverride)

	result.sort(function(a,b){
		if(a.fullname<b.fullname) return -1
		if(a.fullname>b.fullname) return 1
		return 0
	})

	return result
}

export default {
	namespaced: true,

	state: {
		filters: {
			project: null,
			month: null,
			date: null
		},

		tagsDictionary: []

	},

	getters: {
		filters: (state) => {
			return structuredClone(state.filters);
		},
	},

	mutations: {
		// updateFilters(state, payload) {
		// 	let fields = ["project"];
		// 	for (let i = 0; i < fields.length; i++) {
		// 		if (typeof(payload[fields[i]]) !== 'undefined') {
		// 			state.filters[fields[i]] = payload[fields[i]];
		// 		}
		// 	}
		// },
		updateTagsDictionary(state, payload){
            state.tagsDictionary = payload;
        },
		updateProject(state, project){
			state.filters.project=project
		},
		updateMonth(state, month){
			state.filters.month=month
		},
		updateDate(state, date){
			state.filters.date=date
		}
	},

	actions: {
		async getTags({ rootGetters, commit }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/planning/tags/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let tags = adaptTagsAPIResponse(response.data);

			commit('updateTagsDictionary', tags);

			return tags;
		},

		async getProjectShiftDefinitions({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/planning/shift-definitions/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let temp = adaptShiftDefinitionsAPIResponse(response.data);

			return temp;
		},

		async getProjectDailyShifts({rootGetters}, payload){
			let requiredFields = [
				"startDate",
				"endDate",
				"projectId"
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/planning/projects/${d.projectId}/day-shifts/`,
					headers: headers,
					params: {
						start_date: d.startDate,
						end_date: d.endDate,
					}
				});
			} catch (error){
				console.log(error.response.data)
				throw {
					errorType: "request",
					error: error
				}
			}
			
			let adaptedShifts = adaptProjectDailyShiftsAPIResponse(response.data)

			return adaptedShifts;
		},

		async getDayShiftDetails({rootGetters}, payload){
			let requiredFields = [
				"shiftId",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/'+d.shiftId,
					headers: headers,
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			let adaptedDetails = adaptDayShiftDetails(response.data)

			return adaptedDetails
		},

		async saveDayShiftDetails({rootGetters}, payload){
			let requiredFields = ["shiftId"];
			let optionalFields = [
				["startTime", null],
				["duration", null],
				["startTolerance", null],
				["endTolerance", null],
				["startRoundingType", null],
				["startRounding", null],
				["startEarlier", null],
				["startLate", null],
				["endRoundingType", null],
				["endRounding", null],
				["endEarlier", null],
				["endLate", null],
				["color", null],
				["workerDemand", null]
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			let data = {}

			if(d.startTime!=null){
				data.start_time=d.startTime
			}
			if(d.duration!=null){
				data.duration=d.duration
			}
			if(d.startTolerance!=null){
				data.start_tolerance=d.startTolerance
			}
			if(d.endTolerance!=null){
				data.end_tolerance=d.endTolerance
			}
			if(d.startRoundingType!=null){
				data.start_rounding_type=d.startRoundingType
			}
			if(d.startRounding!=null){
				data.start_rounding= d.startRounding
			}
			if(d.startEarlier!=null){
				data.entry_earlier=d.startEarlier
			}
			if(d.startLate!=null){
				data.entry_late=d.startLate
			}
			if(d.endRoundingType!=null){
				data.end_rounding_type=d.endRoundingType
			}
			if(d.endRounding!=null){
				data.end_rounding=d.endRounding
			}
			if(d.endEarlier!=null){
				data.exit_earlier=d.endEarlier
			}
			if(d.endLate!=null){
				data.exit_late=d.endLate
			}
			if(d.color!=null){
				data.color=d.color
			}
			if(d.workerDemand!=null){
				data.worker_demand=d.workerDemand
			}

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/'+d.shiftId,
					headers: headers,
					data: data
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			return adaptDayShiftDetails(response.data)
		},

		async addNoDefShift({rootGetters}, payload){
			let requiredFields = ["name", "day", "projectId"];
			let optionalFields = [
				["startTime", null],
				["duration", null],
				["startTolerance", null],
				["endTolerance", null],
				["startRoundingType", null],
				["startRounding", null],
				["startEarlier", null],
				["startLate", null],
				["endRoundingType", null],
				["endRounding", null],
				["endEarlier", null],
				["endLate", null],
				["color", null],
				["workerDemand", null]
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			let data = {
				name: d.name,
				day: d.day,
				project_id: d.projectId
			}

			if(d.startTime!=null){
				data.start_time=d.startTime
			}
			if(d.duration!=null){
				data.duration=d.duration
			}
			if(d.startTolerance!=null){
				data.start_tolerance=d.startTolerance
			}
			if(d.endTolerance!=null){
				data.end_tolerance=d.endTolerance
			}
			if(d.startRoundingType!=null){
				data.start_rounding_type=d.startRoundingType
			}
			if(d.startRounding!=null){
				data.start_rounding= d.startRounding
			}
			if(d.startEarlier!=null){
				data.entry_earlier=d.startEarlier
			}
			if(d.startLate!=null){
				data.entry_late=d.startLate
			}
			if(d.endRoundingType!=null){
				data.end_rounding_type=d.endRoundingType
			}
			if(d.endRounding!=null){
				data.end_rounding=d.endRounding
			}
			if(d.endEarlier!=null){
				data.exit_earlier=d.endEarlier
			}
			if(d.endLate!=null){
				data.exit_late=d.endLate
			}
			if(d.color!=null){
				data.color=d.color
			}
			if(d.workerDemand!=null){
				data.worker_demand=d.workerDemand
			}

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/no-def/',
					headers: headers,
					data: data
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}
			return adaptShifts([response.data])
		},

		async addShiftsToDay({rootGetters}, payload){
			let requiredFields = [
				"shiftIds",
				"day",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/',
					headers: headers,
					data: {
						shift_definition_ids: d.shiftIds,
						day: d.day,
					}
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			return adaptShifts(response.data)
		},

		async deleteDayShift({rootGetters}, payload){
			let requiredFields = [
				"shiftId",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/'+d.shiftId,
					headers: headers,
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}
		},

		async deleteDayShifts({rootGetters}, payload){
			let requiredFields = [
				"shiftIds",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/',
					headers: headers,
					data: {
						day_shift_ids: d.shiftIds
					}
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}
		},

		async copyShiftOnDay({rootGetters}, payload){
			let requiredFields = [
				"shiftId",
				"day",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/'+d.shiftId+'/copy/',
					headers: headers,
					data: {
						day: d.day,
					}
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			// return adaptShifts(response.data)
		},

		async getProjectWorkers({rootGetters, state}, payload){
			let requiredFields = [
				"projectId", "day"
			];

			let optionalFields = [
				["search", null],
				["tagIds", []],
				["shiftIds", []],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/projects/'+d.projectId+'/workers/',
					headers: headers,
					params: {
						day: d.day,
						search: d.search,
						tag_ids: d.tagIds,
						shift_ids: d.shiftIds	
					}
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			let adaptedWorkers = adaptWorkers(response.data, state.tagsDictionary)

			return adaptedWorkers
		},

		async getDayShiftWorkers({rootGetters, state}, payload){
			let requiredFields = [
				"shiftId",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/'+d.shiftId+'/workers/',
					headers: headers,
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			let adaptedWorkers = adaptDayShiftWorkers(response.data, state.tagsDictionary)

			return adaptedWorkers
		},

		async addWorkersToShifts({rootGetters, state}, payload){
			let requiredFields = [
				"shiftId", "workerIds"
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/'+d.shiftId+'/workers/',
					headers: headers,
					data: {
						worker_ids: d.workerIds
					}
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			let adaptedWorkers = adaptDayShiftWorkers(response.data, state.tagsDictionary)

			return adaptedWorkers
		},

		async deleteWorkersFromShift({rootGetters}, payload){
			let requiredFields = [
				"shiftId", "workerIds"
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/'+d.shiftId+'/workers/',
					headers: headers,
					data: {
						worker_ids: d.workerIds
					}
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}
		},

		async copyShifts({rootGetters}, payload){
			let requiredFields = [
				"shiftIds", "copyType", "dateFrom", "dateTo", "duplicates"
			];

			let optionalFields = [
				["weekDays", null],
				["dayNumber", null]
			]

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let data = {
				day_shift_ids: d.shiftIds,
				copy_type: d.copyType,
				date_from: d.dateFrom,
				date_to: d.dateTo,
				duplicates: d.duplicates
			}

			if(d.copyType=='ONE'){
				if(d.weekDays!==null){
					data.week_days=d.weekDays
				}
				else if(d.dayNumber!=null){
					data.day_number = d.dayNumber
				}
			}

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/planning/day-shifts/copy/',
					headers: headers,
					data: data
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			let adapted = adaptProjectDailyShiftsAPIResponse(response.data)

			return adapted
		},


	}
}