

export default {
    namespaced: true,

    state: {
        resizableColumnsCache: {
            columnsWidths: {
                fullName: 230,
                tags: 220,
                birthDate: 170,
                departmentsLabel: 230,
                projectsLabel: 230,
                statusLabel: 120,
                phoneNumber: 180,
                email: 250,
                cards: 100,
                comment: 140
            }
        }
    },

    getters: {
        columnsWidths: (state) => {
            return state.resizableColumnsCache.columnsWidths;
        }
    },
    mutations: {
        updateDesktopColumnsWidths(state, payload){
            for(let key in payload){
                state.resizableColumnsCache.columnsWidths[key] = payload[key];
            }
        },
    }
}