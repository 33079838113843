import router from './routes'
import * as Sentry from "@sentry/vue";
import Vue from 'vue';
// import { Integrations } from '@sentry/tracing';

if (process.env.VUE_APP_ENVIRONMENT == 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: process.env.VUE_APP_API_URL,
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  //for feature VUE3 

  // Sentry.init({
  //   Vue,
  //   environment: process.env.VUE_APP_ENVIRONMENT,
  //   release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
  //   dsn: process.env.VUE_APP_SENTRY_DSN,
    
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: process.env.VUE_APP_API_URL,
  //       tracePropagationTargets: process.env.VUE_APP_API_URL,
  //     }),
  //   ],
  //   debug: process.env.VUE_APP_ENVIRONMENT  !==  'production',
  //   tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
  //   tracingOptions: {
  //     trackComponents: true,
  //   },
  //   // Vue specific
  //   logErrors: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
  //   attachProps: true,
  //   attachStacktrace: true,
  // });
}
