import createPersistedState from "vuex-persistedstate";
import structuredClone from '@ungap/structured-clone';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import appConfig from '@/app_config';
import axios from "axios";

function adaptTenantDataResponse(response){
    let tenantData = {
        user: null,
        personalData: null,
        worker: false,
        permissions: [],
        name: null,
        slug: null
    };

    // Adapting user
    let ru = response.user;
    tenantData.user = {
        id: parseInt(ru.id),
        username: ru.username
    };
    
    // Adapting personal data
    let rpd = response.personal_data;
    if(rpd != null){
        tenantData.personalData = {
            id: parseInt(rpd.id),
            uniquePersonalId: rpd.unique_personal_id,
            birthDate: Date.parse(rpd.birth_date),
            email: rpd.email,
            phoneNumber: rpd.phone_number,
            firstName: rpd.first_name,
            lastName: rpd.last_name,
            madenName: rpd.maden_name,
            middleName: rpd.middle_name,
            comment: rpd.comment
        };
    }

    // Adapting worker
    if (typeof(response.worker) !== 'boolean') throw "worker has to be a Boolean value";
    tenantData.worker = response.worker;

    // Adapting permissions
    let rp = response.permissions;
    if (typeof(rp) !== typeof([])) throw "permissions has to be an array";
    for(let i = 0; i < rp.length; i++){
        tenantData.permissions.push({
            contentType: rp[i].content_type,
            codename: rp[i].codename
        });
    }

    // Adapting tenant
    let rt = response;
    tenantData.name = rt.name;
    tenantData.slug = rt.slug;

    return tenantData;
}

export default {
    namespaced: true,
    plugins: [createPersistedState()],

    state: {
        /**
         * Structure:
         *  {
         *      user: {
         *          id: {Number},
         *          username: {String}
         *      },
         *      personalData: {
         *          id: {Number},
         *          uniquePersonalId: {String},
         *          birthDate: {Date},
         *          email: {String},
         *          phoneNumber: {String},
         *          firstName: {String},
         *          lastName: {String},
         *          madenName: {String},
         *          middleName: {String},
         *          comment: {String}
         *      },
         *      worker: {Boolean},
         *      permissions: [
         *          {
         *              contentType: {String},
         *              codename: {String}
         *          },
         *          { ... }
         *      ],
         *      tenant: {
         *          name: {String},
         *          slug: {String}
         *      }
         *  }
         */
        currentTenant: null,
        /**
         * Structure:
         *  [
         *      {
         *          name: {String},
         *          slug: {String}
         *      },
         *      { ... }
         *  ]
         */
        allTenants: null
    },

    getters: {
        currentTenantSlug: (state) => {
            return (state.currentTenant == null) ? null : state.currentTenant.slug;
        },
        currentTenantPermissions: (state) => {
            return (state.currentTenant == null) ? null : state.currentTenant.permissions;
        },
        currentTenant: (state) => {
            return structuredClone(state.currentTenant);
        },
        allTenants: (state) => {
            return structuredClone(state.allTenants);
        },
        tenantsCount: (state) => {
            return state.allTenants.length;
        }
    },

    mutations: {
        updateCurrentTenant(state, payload){
            state.currentTenant = payload;
        },
        updateAllTenants(state, payload){
            state.allTenants = payload;
        },
        clearData(state){
            state.currentTenant = null;
            state.allTenants = null;
        }
    },

    actions: {
        logout({commit}){
            commit("clearData");
        },

        /**
         * Passes data to adaptTenantDataResponse function and returns its result
         * @param {*} param0 Provided by Vuex
         * @param {Object} payload Data in the same format as the response from /api/v1/tenant/<str:tenant_slug>
         */
        // eslint-disable-next-line no-empty-pattern
        adaptTenant({}, payload){
            return adaptTenantDataResponse(payload);
        },

        /**
         * GET request for user tenant data
         * @param {*} param0 Provided by Vuex
         * @param {Object} payload Required format
         *  {
         *      slug: {String}
         *  }
         */
        async getTenant({rootGetters}, payload){
            let requiredFields = [
                "slug"
            ];
            let tmp = validateRequiredAndOptional(payload, requiredFields, null);
			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

            let data = tmp.validatedData;
            let headers = rootGetters.standardRequestHeaders;
			let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(null) + "/api/v1/tenant/" + data.slug,
                    headers: headers
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let adapted = null;
            try {
                adapted = adaptTenantDataResponse(response.data);
            } catch (error) {
                throw {
                    errprType: "adapting",
                    error: error
                };
            }

            return adapted;
        },

        /**
         * Selects provided tenant and retrieves its data
         * @param {*} param0 Provided by Vuex
         * @param {Object} payload Required format
         *  {
         *      slug: {String}
         *  }
         */
        async selectTenant({commit, dispatch}, payload){
            let adaptedData = await dispatch("getTenant", payload);
            commit("updateCurrentTenant", adaptedData);
            return structuredClone(adaptedData);
        },

        /**
         * Adapts API response with tenants list
         * @param {*} param0 Provided by Vuex
         * @param {Object} payload API response with tenants list
         */
        // eslint-disable-next-line no-empty-pattern
        adaptTenantsList({}, payload){
            let adapted = [];
            let tenantsList = payload;

            for(let i = 0; i < tenantsList.length; i++){
                adapted.push({
                    name: tenantsList[i].name,
                    slug: tenantsList[i].slug
                });
            }

            return adapted;
        },

        async RetrieveAllTenants({commit, dispatch, rootGetters}){
            let headers = rootGetters.standardRequestHeaders;
			let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(null) + "/api/v1/tenants/my-tenants",
                    headers: headers
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let adapted;
            try {
                adapted = dispatch("adaptTenantsList", response.data);
            } catch(error){
                throw {
                    errorType: "adapting",
                    error: error
                };
            }

            commit("updateAllTenants", adapted);

            return structuredClone(adapted);
        }
    }
}