import axios from 'axios';
import appConfig from '@/app_config';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import structuredClone from '@ungap/structured-clone';


// #########################
// Payload Adapters
// #########################
function adaptProjects(data){
	let result = []
	
	for(let item of data){
		result.push({
			id: item.id,
			clientId: item.client_id,
			name: item.name,
			description: item.description
		})
	}

	return result
}

function adaptPermissionGroups(data){
	let result = []

	for(let item of data.roles){
		result.push({
			id: item.id,
			name: item.name
		})
	}

	return result
}

function adaptStateGroupPermissions(data, permissionGroupsDictionary){
	let result = []
	let temp

	for(let item of data){
		temp = permissionGroupsDictionary.find(i=> i.id ==item.group_id)
		result.push({
			id: item.id,
			name: temp? temp.name: "",
			groupId: item.group_id,
			permissions: {
				view: item.perm_read,
				change: item.perm_update,
				delete: item.perm_delete,
				add: item.perm_create,
				changeState: item.perm_change_state,
				audit: item.perm_view_audit,
			}
		})
	}

	return result
}

function adaptTransitions(data){
	let result = []

	for(let item of data){
		result.push({
			id: item.id,
			from: item.state_from_id,
			to: item.state_to_id,
			name: item.name,
			translations: {
				en: item.name_en,
				pl: item.name_pl,
				de: item.name_de,
			}
		})
	}

	return result
}

function adaptStates(data, permissionGroupsDictionary){
	let result = []
	let temp
	
	for(let item of data){
		temp = {
			id: item.id,
			projectId: item.project_id,
			name: item.name,
			translations: {
				en: item.name_en,
				pl: item.name_pl,
				de: item.name_de
			},
			code: item.code,
			color: item.color,
			required: false, //TODO
			groupPermissions: adaptStateGroupPermissions(item.group_permissions, permissionGroupsDictionary),
			transitions: adaptTransitions(item.transition_states)

		}
		result.push(temp)
	}

	return result
}

function adaptModulesStates(data){
	let result = {
		states: [],
		configs: [],
	}
	let temp
	
	for(let item of data.module_states){
		temp = {
			id: item.id,
			module: item.module,
			projectId: item.project_id,
			required: item.name==='DEFAULT',
			state: {
				id: item.state.id,
				code: item.state.code,
				color: item.state.color,
				transitions: adaptTransitions(item.state.transition_states),
				translations: {
					en: item.state.name_en,
					pl: item.state.name_pl,
					de: item.state.name_de
				},
			},
			name: item.name,			
		}
		result.states.push(temp)
	}

	for(let item of data.configs){
		result.configs.push({
			module: item.module,
			timesheetsReadOnly: item.timesheets_read_only
		})
	}

	return result
}

function adaptCopyApprovalProcessAPIResponse(data){
	let result = {
		states: [],
		conflicts: [],
		statesWithRecords: []
	}

	for(let item of data.states){
		result.states.push({
			id: item.id,
			name: item.name,
			code: item.code,
		})
	}

	for(let item of data.conflicts){
		result.conflicts.push({
			currentState: {
				id: item.current_state.id,
				name: item.current_state.name,
				code: item.current_state.code,
			},
			addedState: {
				id: item.added_state.id,
				name: item.added_state.name,
				code: item.added_state.code,
			},
		})
	}

	for(let item of data.states_with_records){
		result.statesWithRecords.push({
			id: item.id,
			name: item.name,
			code: item.code,
		})
	}

	return result
}


export default {
	namespaced: true,

	state: {
		nodesPositions: {},
		networkProperties: {
			// {projectId}: {
			// 	position: {
			// 		x: 0,
			// 		y: 0
			// 	},
			// 	scale: 1
			// }
		},

		permissionGroupsDictionary: [],
	},

	getters: {
		nodesPositions: (state) => {
			return structuredClone(state.nodesPositions);
		},
		networkProperties: (state) => {
			return structuredClone(state.networkProperties);
		},

		projectNodesPositions: (state) => (projectId) => {
			return structuredClone(state.nodesPositions[projectId]);
		},
		projectNetworkProperties: (state) => (projectId) => {
			return structuredClone(state.networkProperties[projectId]);
		},
	},

	mutations: {
		// updateNetworkProperties(state, payload){
		// 	state.networkProperties = structuredClone(payload)
		// },
		// updateAllNodesPositions(state, payload){
		// 	for(let key in payload){
		// 		if(!state.nodesPositions[key]){
		// 			state.nodesPositions[key]={x: null, y: null}
		// 		}
		// 		state.nodesPositions[key].x=payload[key].x
		// 		state.nodesPositions[key].y=payload[key].y
		// 	}

		// },
		// updateNodePosition(state, payload){
		// 	if(!state.nodesPositions[payload.id]){
		// 		state.nodesPositions[payload.id]={x: null, y: null}
		// 	}
		// 	state.nodesPositions[payload.id].x=payload.x
		// 	state.nodesPositions[payload.id].y=payload.y
		// },
		updateProjectNetworkProperties(state, payload){
			if(!state.networkProperties[payload.projectId]){
				state.networkProperties[payload.projectId] = {}
			}
			state.networkProperties[payload.projectId] = structuredClone(payload.properties)
		},
		updateProjectAllNodesPositions(state, payload){
			if(!state.nodesPositions[payload.projectId]){
				state.nodesPositions[payload.projectId] = {}
			}
			let temp = state.nodesPositions[payload.projectId]
			for(let key in payload.nodes){
				if(!temp[key]){
					temp[key]={x: null, y: null}
				}
				temp[key].x=payload.nodes[key].x
				temp[key].y=payload.nodes[key].y
			}

		},
		updateNodePosition(state, payload){
			if(!state.nodesPositions[payload.projectId]){
				state.nodesPositions[payload.projectId] = {}
			}

			let temp = state.nodesPositions[payload.projectId]

			if(!temp[payload.id]){
				temp[payload.id]={x: null, y: null}
			}
			temp[payload.id].x=payload.x
			temp[payload.id].y=payload.y
		},
		updatePermissionGroupsDictionary(state, payload){
			state.permissionGroupsDictionary = payload;
		},
	},

	actions: {
		async getProjects({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/admin/timesheet-states/projects/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let projects = adaptProjects(response.data);

			return projects;
		},

		async getPermissionGroups({ rootGetters, commit }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/admin/roles/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let groups = adaptPermissionGroups(response.data);

			commit('updatePermissionGroupsDictionary', groups);

			return groups
		},

		async getProjectModules({ rootGetters }, payload) {
			let requiredFields = ["projectId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/projects/${d.projectId}/modules/`,
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let modules = adaptModulesStates(response.data);

			return modules;
		},

		async updateModule({ rootGetters }, payload) {
			let requiredFields = ["projectId", "moduleType", "defaultStateId"];

			let optionalFields = [
				["sendForApprovalStateId", undefined],
				["readOnly", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = {
				project_id: d.projectId,
				module: d.moduleType,
				default_state_id: d.defaultStateId,
			}

			if(typeof(d.sendForApprovalStateId)!='undefined'){
				data.accept_state_id = d.sendForApprovalStateId
			}

			if(d.readOnly!=null && d.moduleType==0){
				data.read_only = d.readOnly
			}

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/modules/`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},

		async getProjectStates({ rootGetters, state }, payload) {
			let requiredFields = ["projectId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/projects/${d.projectId}/states/`,
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let states = adaptStates(response.data, state.permissionGroupsDictionary);

			return states;
		},
		async addState({ rootGetters }, payload) {
			let requiredFields = ["projectId", "name", "code", "transitions", "groupPermissions"];

			let optionalFields = [
				["color", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = {
				project_id: d.projectId,
				name: d.name,
				code: d.code,
				transition_states: d.transitions,
				group_permissions: d.groupPermissions,
			}

			if(d.color!=null){
				data.color = d.color
			}

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},
		async updateState({ rootGetters }, payload) {
			let requiredFields = ["stateId", "name", "code", "transitions", "groupPermissions"];

			let optionalFields = [
				["color", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = {
				name: d.name,
				code: d.code,
				transition_states: d.transitions,
				group_permissions: d.groupPermissions,
			}

			if(d.color!=null){
				data.color = d.color
			}

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/${d.stateId}`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},
		async deleteState({ rootGetters }, payload) {
			let requiredFields = ["stateId", "stateToId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/${d.stateId}`,
					headers: headers,
					data: {
						state_to_id: d.stateToId
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},
		async addTransition({ rootGetters }, payload) {
			let requiredFields = ["stateFrom", "stateTo"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			let data = {
				state_from_id: d.stateFrom,
				state_to_id: d.stateTo,
			}

			let response = null

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/transitions/`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async updateTransition({ rootGetters }, payload) {
			let requiredFields = ["transitionId"];

			let optionalFields = [
				["name", null],
				["nameEn", null],
				["namePl", null],
				["nameDe", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			let data = {}

			if(d.name!=null){
				data.name = d.name
			}
			if(d.nameEn!=null){
				data.name_en = d.nameEn
			}
			if(d.namePl!=null){
				data.name_pl = d.namePl
			}
			if(d.nameDe!=null){
				data.name_de = d.nameDe
			}

			let response = null

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/transitions/${d.transitionId}`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async deleteTransition({ rootGetters }, payload) {
			let requiredFields = ["transitionId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/timesheet-states/transitions/${d.transitionId}`,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},

		async copyApprovalProcess({ rootGetters }, payload) {
			let requiredFields = ["currentProjectId", "newProjectId", "option"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data={
				project_id: d.newProjectId,
				option: d.option
			}

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/projects/${d.currentProjectId}/timesheet-states/`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adaptedResponse = adaptCopyApprovalProcessAPIResponse(response.data)

			return adaptedResponse
		},

		async resolveConflicts({ rootGetters }, payload) {
			let requiredFields = ["option", "transferRecordStates", "states"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data={
				option: d.option
			}

			if(d.option=='ADD'){
				data.states = d.states
			}
			else if(d.option=='REPLACE'){
				data.transfer_record_states = d.transferRecordStates
			}

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/projects/timesheet-states/conflicts/`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		}

	}
}