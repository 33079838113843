import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import axios from 'axios';
import appConfig from '@/app_config';
import structuredClone from '@ungap/structured-clone';

function dateToString(date) {
	let y, m, d;

	y = date.getFullYear();
	m = date.getMonth() + 1;
	d = date.getDate();

	if (m < 10) m = '0' + m;
	if (d < 10) d = '0' + d;

	return `${y}-${m}-${d}`;
}

function __adaptProjectsResponse(response) {
	let result = [];
	let obj, project;

	for (let i = 0; i < response.length; i++) {
		project = response[i];
		obj = {
			id: project.project.id,
			name: project.project.name,
			payRate: project.project.payRate,
			currancy: project.project.payRate_currency
		};
		result.push(obj);
	}
	return result;
}

function __adaptTimeEntriesResponse(response) {
	let record = null;
	let date = null;
	let	dateStr = null;
	let result = {};
	let obj, child, entryAt, leaveAt, numMins;

	for (let i = 0; i < response.length; i++) {
		record = response[i];

		date = Date.parse(record.date);
		dateStr = dateToString(date);

		if (typeof(result[dateStr]) === 'undefined') {
			result[dateStr] = [];
		}

		switch (record.child_type) {
			case "IN_OUT":
				entryAt = Date.parse(record.child.entry_registered_at);
				leaveAt = Date.parse(record.child.leave_registered_at);

				numMins = Math.floor((leaveAt.getTime() - entryAt.getTime()) / 60000);

				child = {
					entryAt: entryAt,
					leaveAt: leaveAt,
					numMins: numMins
				};
				break;

			case "RAW_HOURS":
				child = {
					numMins: record.child.num_mins
				};
				break;
			default:
				throw "Unexpected hours configuration";
		}

		let category;
		if (record.category == null) {
			category = {
				id: null,
				name: null
			};
		} else {
			category = {
				id: record.category.id,
				name: record.category.name
			}
		}

		obj = {
			id: record.id,
			permissionChange: record.change,
			permissionDelete: record.delete,
			workerId: record.worker_id,
			project: {
				id: record.project.id,
				name: record.project.name,
				payRate: record.project.payRate,
				currancy: record.project.payRate_currency,
			},
			stateId: record.state_id,
			shiftId: record.shift_id,
			date: date,
			description: record.comment,
			category: category,
			childType: record.child_type,
			child: child
		};

		result[dateStr].push(obj);
	}

	return result;
}

export default {
	namespaced: true,

	state: {
		projectsCachedAt: null,
		projects: null,

		dailyRecordsCachedAt: null,
		dailyRecords: null,
		// {
		//	 {DateStr}: [
		//		 {
		//			 id: {Number},
		//			 permissionChange: {Boolean},
		//			 permissionDelete: {Boolean},

		//			 workerId: {Number},
		//			 project: {
		//				 id: {Number},
		//				 name: {String},
		//			 },
		//			 stateId: {Number},
		//			 shiftId: {Number},

		//			 category: {
		//				 id: {Number},
		//				 name: {String}
		//			 },

		//			 childType: {String}, // RAW_HOURS | IN_OUT
		//			 child: {
		//				 // CASE: IN_OUT
		//				 entryAt: {Date},
		//				 leaveAt: {Date},
		//				 numMins: {Numbers},

		//				 // CASE: RAW_HOURS
		//				 numMins: {Numbers}
		//			 },
		//
		//			 description: {String}
		//		 }
		//	 ]
		// }

		chosenMonth: null,
		filters: {
			search: null,
			project: null
		},

		registerWorktime: {
			mode: 'hours',
			saveType: 0,
		}
	},

	getters: {
		chosenMonth: (state) => {
			return state.chosenMonth;
		},

		filters: (state) => {
			return state.filters;
		},

		projects: (state) => {
			if (state.projects == null) return null;

			return structuredClone(state.projects);
		},

		dailyRecords: (state) => {
			if (state.dailyRecords == null) return null;

			return structuredClone(state.dailyRecords);
		},

		registerWorktimeFormParams: (state) => {
			return structuredClone(state.registerWorktime)
		},
	},

	mutations: {
		updateChosenMonth(state, payload) {
			state.chosenMonth = payload;
		},

		updateRegisterWorktimeMode(state, payload) {
			state.registerWorktime.mode = payload;
		},

		updateRegisterWorktimeSaveType(state, payload) {
			state.registerWorktime.saveType = payload;
		},

		updateFilters(state, payload) {
			if (typeof(payload.search) !== 'undefined') {
				state.filters.search = payload.search;
			}
			if (typeof(payload.project) !== 'undefined') {
				state.filters.project = payload.project;
			}
		},

		updateProjectsCache(state, payload) {
			state.projects = payload;
			state.projectsCachedAt = new Date();
		},

		updateDailyRecordsCache(state, payload) {
			// In the future it should be more sophisticated
			state.dailyRecords = payload;
			state.dailyRecordsCachedAt = new Date();
		},

		deleteTimeEntry(state, payload) {
			// payload as id

			let day;
			for (let key in state.dailyRecords) {
				day = state.dailyRecords[key];

				for (let i = 0; i < day.length; i++) {
					if (day[i].id == payload) {
						day.splice(i, 1);
						return true;
					}
				}
			}

			return false;
		}
	},

	actions: {
		async getTimeEntries({ commit, rootGetters }, payload) {
			let requiredFields = [
				"startDate",
				"endDate"
			];
			let optionalFields = [
				["search", null],
				["project", undefined],
				["state", undefined]
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/rcp/time-entries/",
					headers: headers,
					params: {
						start_date: d.startDate,
						end_date: d.endDate,
						search: d.search,
						project: d.project,
						state: d.state
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				}
			}

			let newCache = null;
			try {
				newCache = __adaptTimeEntriesResponse(response.data);
			} catch (error) {
				throw {
					errorType: "adapting",
					error: error
				};
			}
			commit('updateDailyRecordsCache', newCache);

			return structuredClone(newCache);
		},

		async getProjects({ commit, rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/rcp/worker-projects/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				}
			}

			let newCache = null;
			try {
				newCache = __adaptProjectsResponse(response.data);
			} catch (error) {
				throw {
					errorType: "adapting",
					error: error
				};
			}
			commit('updateProjectsCache', newCache);

			return structuredClone(newCache);
		},

		async deleteTimeEntry({ commit, rootGetters }, payload) {
			let requiredFields = [
				"id"
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, null);
			let d = tmp.validatedData;

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/rcp/time-entry/" + d.id,
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				}
			}

			return commit('deleteTimeEntry', d.id);
		},

		async copyTimeEntry({ rootGetters }, payload) {
			let requiredFields = [
				"project_id", "category_id", "date", "child_type", "child"
			];
			let optionalFields = [
				["comment", ""]
			]

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let data = tmp.validatedData;

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/rcp/time-entries/",
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				}
			}
		},

		async getProjectCategories({ rootGetters }, payload) {
			let requiredFields = [
				"projectId",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;


			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/rcp/projects/${d.projectId}/categories/`,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				}
			}

			return response.data
		},

		async moveTimeEntry({ rootGetters }, payload) {
			let requiredFields = [
				"timeEntryId", "project_id", "category_id", "date"
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			let data = {
				project_id: d.project_id,
				category_id: d.category_id,
				date: d.date
			}

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/rcp/time-entry/"+d.timeEntryId,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				}
			}
		},

		async exportTimeEntries({rootGetters}, payload){
			let requiredFields = [
				"requestData",
				"formatCode",
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, null);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/rcp/export/" + d.formatCode,
					headers: headers,
					responseType: "blob",
					data: d.requestData
				});
			} catch(error){
				throw {
					errorType: "request",
					error: error
				};
			}

			return response;
		}
	}
}