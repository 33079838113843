
export default function validateRequiredAndOptional(payload, requiredFields=null, optionalFields=null){
    let validatedData = {};
    let valid = true;
    let errors = {};

    if(requiredFields != null){
        for(let i = 0; i < requiredFields.length; i++){
            if(typeof(payload[requiredFields[i]]) === 'undefined'){
                valid = false;
                errors[requiredFields[i]] = {
                    string: "This field is required",
                    code: "required"
                };
            }
            validatedData[requiredFields[i]] = payload[requiredFields[i]];
        }
    }

    if(optionalFields != null){
        for(let i = 0; i < optionalFields.length; i++){
            if(typeof(payload[optionalFields[i][0]]) == 'undefined'){
                validatedData[optionalFields[i][0]] = optionalFields[i][1];
            }
            else{
                validatedData[optionalFields[i][0]] = payload[optionalFields[i][0]];
            }
        }
    }

    if(!valid){
        return {
            valid: false,
            errors: errors
        }
    }

    return {
        valid: true,
        validatedData: validatedData
    };
}

