import axios from 'axios';
import appConfig from '@/app_config';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import structuredClone from '@ungap/structured-clone';
import createPersistedState from "vuex-persistedstate"

// #########################
// Calendars Payload Adapters
// #########################
function __adaptPeriods(periods) {
    let adapted = [];

    for(let i = 0; i < periods.length; i++){
        adapted.push({
            calendarId: periods[i].calendar_id,
            id: periods[i].id,
            identifier: periods[i].identifier,
            type: periods[i].type,
            startDate: periods[i].start_date,
            endDate: periods[i].end_date
        });
    }

    return adapted;
}

function adaptCalendarsAPIResponse(apiResponse) {
    let adapted = [];
    let tmp, p;

    for (let i = 0; i < apiResponse.length; i++) {
        p = apiResponse[i];
        tmp = {
            id: p.id,
            identifier: p.identifier,
            name: p.name,
            comment: p.comment,
            weeks: null,
            months: null,
            quarters: null,
            years: null
        };

        //let adaptedPeriods = __adaptPeriods(p.calendar_indexes)
        //tmp.periods = adaptedPeriods
        tmp.weeks = __adaptPeriods(p.weeks);
        tmp.months = __adaptPeriods(p.months);
        tmp.quarters = __adaptPeriods(p.quarters);
        tmp.years = __adaptPeriods(p.years);

        adapted.push(tmp);
    }
    return adapted;
}

// #########################
// Period Summary Details Payload Adapters
// #########################

function adaptPeriodSummaryAPIResponse(apiResponse) {

    let adapted = {
        totalItems: apiResponse.total_items,
        workers: []
    };
    let workers = apiResponse.workers

    let tmp, w;

    for (let i = 0; i < workers.length; i++) {
        w = workers[i];
        tmp = {
            id: w.id,
            status: w.status,
            firstName: w.personal_data.first_name,
            lastName: w.personal_data.last_name,
            // total: {
            // 	workTime: 0,
            // 	nightHours: 0,
            // 	breakHours: 0,
            // 	holidayHours: 0,
            // 	saturdayHours: 0,
            // 	sundayHours: 0,
            // 	overtimeHours: 0,					
            // },
            projects: []
        }

        for (let p of w.projects) {
            let newProject = {
                id: p.id,
                name: p.name,
                summary: []
            }
            for (let item of p.summary) {
                newProject.summary.push({
                    id: item.id,
                    periodId: item.period_id,
                    manualInput: item.manual_input,
                    detailType: item.detail_type,
                    numMins: item.num_mins
                })
            }

            tmp.projects.push(newProject)
        }

        adapted.workers.push(tmp);
    }
    return adapted;
}

function adaptDailyPeriodSummaryAPIResponse(apiResponse) {

    let adapted = [];
    let workers = apiResponse.workers

    let tmp, w;

    for (let i = 0; i < workers.length; i++) {
        w = workers[i];
        tmp = {
            id: w.id,
            status: w.status,
            firstName: w.personal_data.first_name,
            lastName: w.personal_data.last_name,
            projects: []
        }

        for (let p of w.projects) {
            let newProject = {
                id: p.id,
                name: p.name,
                datesSummary: {}
            }
            for (let item of p.dates) {
                newProject.datesSummary[item.date] = []

                for (let s of item.summary) {
                    newProject.datesSummary[item.date].push({
                        numMins: s.num_mins,
                        detailType: s.type
                    })
                }
            }

            tmp.projects.push(newProject)
        }

        adapted.push(tmp);
    }
    return adapted;
}


// #########################
// Projects Payload Adapters
// #########################
function adaptProjectsAPIResponse(apiResponse) {
    let adapted = [];
    let tmp, p;

    for (let i = 0; i < apiResponse.length; i++) {
        p = apiResponse[i];
        tmp = {
            id: p.id,
            name: p.name,
            timesheetsPermissions: {
                add: p.timesheets_perm.add,
                view: p.timesheets_perm.view,
                change: p.timesheets_perm.change,
                delete: p.timesheets_perm.delete,
                changeState: p.timesheets_perm.change_state,
                viewAudit: p.timesheets_perm.view_audit
            },
            draftsPermissions: {
                add: p.drafts_perm.add,
                view: p.drafts_perm.view,
                change: p.drafts_perm.change,
                delete: p.drafts_perm.delete
            }
        };
        adapted.push(tmp);
    }
    return adapted;
}

// #########################
// Shifts Payload Adapters
// #########################
function adaptShiftsAPIResponse(apiResponse) {
    let adapted = [];

    for (let i = 0; i < apiResponse.length; i++) {
        let p = apiResponse[i];
        adapted.push({
            id: p.id,
            name: p.name
        })
    }
    return adapted;
}

// #########################
// Tags Payload Adapters
// #########################
function adaptTagsAPIResponse(apiResponse) {
    let adapted = [];

    for (let i = 0; i < apiResponse.length; i++) {
        let p = apiResponse[i];
        adapted.push({
            id: p.id,
            name: p.choice_name,
            color: p.color
        })
    }
    return adapted;
}


// ##########################
// State Payload Adapters
// ##########################
function __adaptStates(apiResponse) {
    let adapted = {
        states: [],
        // transitionsMatrix: [],
    };
    let tmp, p;

    for (let i = 0; i < apiResponse.states.length; i++) {
        p = apiResponse.states[i];
        tmp = {
            id: p.id,
            name: p.name,
            readonly: p.readonly,
            default: p.default,
            code: p.code,
            color: p.color,
            projectId: p.project_id,
            view: p.view,
            add: p.add,
            change: p.change,
            delete: p.delete,
            changeState: p.change_state,
            viewAudit: p.view_audit,
            transitions: []
        };
        adapted.states.push(tmp);
    }

    for (let i = 0; i < apiResponse.transitions_matrix.length; i++) {
        p = apiResponse.transitions_matrix[i];
        let idx = adapted.states.findIndex(i => i.id==p.state_id)
        if(idx!=-1){
            adapted.states[idx].transitions = p.target_state_ids
        }
        // tmp = {
        //     stateId: p.state_id,
        //     targetStateIds: p.target_state_ids
        // };
        // adapted.transitionsMatrix.push(tmp);
    }
    return adapted;
}


/* #endregion */

export default {
    namespaced: true,

	plugins: [createPersistedState()],

	state: {
		recordDetailsTimesheetSectionColumnsVisibility: {
			entry: true,
			leave: true,
			totalHours: true,
			dayHours: true,
			nightHours: true,
			breakHours: true,
			overHours: true,
			holidayHours: true,
			comment: true
		},

		resizableColumnsCache: {
            desktopColumnsWidths: [
                200, // full name
                120, // 7 date columns
                120,
                120,
                120,
                120,
                120,
                120,
                120, // Summary column
                200, //Tags column
                200, //Shifts column
            ],
            desktopColumnsVisible: {
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
                7: true,
                8: true,
                9: false,
                10: false,
                11: false,
                12: false,
                13: false,
                14: false,
            },
            summaryDesktopColumnsWidths: [
                200, // full name
                120, // Summary column
                120, //6 summary details column
                120,
                120,
                120,
                120,
                120,
            ],

            workerSummaryDesktopColumnsWidths: [
                200, // full name
                120, // Working hours column
                120, // Summary column
                120, //6 summary details column
                120,
                120,
                120,
                120,
                120,
            ],
        },

		currentProjects: null,
		// [
		// 	{
		// 		id: {Number},
		// 		name: {String},
		// 		add: {Boolean},
		// 		view: {Boolean},
		// 		change: {Boolean},
		// 		delete: {Boolean},
		// 		changeState: {Boolean}
		// 	}
		// ],

		timesheetsView: 'hours',

		cachedValues: {
			date: null
		},

		filters: {
			searchValue: null,
			project: -1,
			worker: null,
			page: null,
			perPage: null,
			shifts: null,
			tagsType: 0,
			tags: null,
            state: -1,
		},

		currentPage: null,
		/* #region  CurrentPage format */
		// {
		// 	page: 1,
		// 	numPages: 2,
		// 	itemsPerPage: 10,
		//
		// 	workers: [
		// 		{
		// 			id: {Number},
		// 			firstName: {String},
		// 			lastName: {String},
		// 			days: [
		// 				{
		// 					date: {Date},
		// 					leaves: [
		// 						{
		// 							id: {Number}, // leave assignment id
		// 							status: {Number},
		// 							workerId: {Number},
		// 							projectId: {Number},
		// 							leaveType: {
		// 								id: {Number},
		// 								code: {String},
		// 								name: {String},
		//								description: {String},
		// 								color: {String},
		// 								bgColor: {String}
		// 							},
		// 						}
		// 					],
		// 					timesheets: [
		// 						{
		// 							id: {Number},
		// 							workerId: {Number},
		// 							projectId: {Number},
		// 							stateId: {Number},
		// 							shiftId: {Number},
		// 							date: {Date},
		// 							details: [
		// 								{
		// 									id: {Number},
		// 									timeEntryId: {Number},
		// 									numMins: {Number},
		// 									type: {Number}
		// 								}
		//							],
		// 							attributes: [
		// 								{
		// 									id: {Number},
		// 									timeEntryId: {Number},
		// 									name: {String},
		// 									code: {String},
		// 									value: {String}
		// 								}
		// 							],
		//
		// 							childType: "RAW_HOURS/IN_OUT",
		// 							child: {
		// 								workTime: {Number}, // Work time in minutes
		// 								timeEntryId: {Number},
										
		// 								// RAW_HOURS
		// 								numMins: {Number},

		// 								// IN_OUT
		// 								entryRegisteredAt: {Date},
		// 								entryRegisteredAtUTC: {Date},
		// 								leaveRegisteredAt: {Date},
		// 								leaveRegisteredAtUTC: {Date}
		// 							},

		// 							add: true,
		// 							view: true,
		// 							change: true,
		// 							delete: true,
		// 							viewAudit: true,
		// 							changeState: true
		// 						}
		// 					],
		// 					drafts: [
		// 						{
		// 							id: {Number},
		// 							workerId: {Number},
		// 							projectId: {Number},
		// 							registeredAt: {Date},
		// 							registeredAtUTC: {Date},
		// 							type: {Number},

		// 							add: true,
		// 							view: true,
		// 							change: true,
		// 							delete: true
		// 						}
		// 					],
		// 				}
		// 			],
		// 			leaves: [
		// 				{
		// 					id: {Number}, // leave assignment id
		// 					status: {Number},
		// 					workerId: {Number},
		// 					projectId: {Number},
		// 					leaveType: {
		// 						id: {Number},
		// 						code: {String},
		// 						name: {String},
		//						description: {String},
		// 						color: {String},
		// 						bgColor: {String}
		// 					},
		// 					dateFrom: {Date},
		// 					dateTo: {Date}
		// 				}
		// 			]
		// 		}
		// 	]
		// }
		/* #endregion */

	},

	getters: {
		recordDetailsTimesheetSectionColumnsVisibility: (state) => {
			return state.recordDetailsTimesheetSectionColumnsVisibility;
		},

        summaryDesktopColumnsWidths: (state) => {
            return state.resizableColumnsCache.summaryDesktopColumnsWidths;
        },
        workerSummaryDesktopColumnsWidths: (state) => {
            return state.resizableColumnsCache.workerSummaryDesktopColumnsWidths;
        },
        currentPageCache: (state) => {
            return structuredClone(state.currentPage);
        },
        currentProjectsCache: (state) => {
            return structuredClone(state.currentProjects);
        },
        filters: (state) => {
            return structuredClone(state.filters);
        },
        cachedValues: (state) => {
            return structuredClone(state.cachedValues);
        },
    },

	mutations: {
		/**
		 * Updates cached columns visibility values
		 * @param {*} state Provided by Vuex
		 * @param {Object} payload Required format (all fields optional)
		 * 	{
		 * 		[entry]: {Boolean},
		 * 		[leave]: {Boolean},
		 * 		[totalHours]: {Boolean},
		 * 		[dayHours]: {Boolean},
		 * 		[nightHours]: {Boolean},
		 * 		[breakHours]: {Boolean},
		 * 		[overHours]: {Boolean},
		 * 		[holidayHours]: {Boolean},
		 * 		[comment]: {Boolean}
		 * 	}
		 */
		updateRecordDetailsTimesheetSectionColumnsVisibility(state, payload){
			let fieldsMapping = [
				// [<state field name>, <corresponding payload field name>
				["entry", "entry"],
				["leave", "leave"],
				["totalHours", "totalHours"],
				["dayHours", "dayHours"],
				["nightHours", "nightHours"],
				["breakHours", "breakHours"],
				["overHours", "overHours"],
				["holidayHours", "holidayHours"],
				["comment", "comment"]
			];

			for(let i = 0; i < fieldsMapping.length; i++){
				if(typeof(payload[fieldsMapping[i][1]]) === 'boolean'){
					state.recordDetailsTimesheetSectionColumnsVisibility[fieldsMapping[i][0]] = payload[fieldsMapping[i][1]];
				}
			}
		},

        updateDesktopColumnsWidths(state, payload) {
            // for(let i = 0; i < state.resizableColumnsCache.desktopColumnsWidths.length && i < payload.length; i++){
            //     state.resizableColumnsCache.desktopColumnsWidths[i] = payload[i];
            // }
            for (let key in payload) {
                state.resizableColumnsCache.desktopColumnsWidths[key] = payload[key]
            }
        },

        updateDesktopColumnsVisible(state, payload) {
            for (let key in payload) {
                state.resizableColumnsCache.desktopColumnsVisible[key] = payload[key]
            }
        },

        updateSummaryDesktopColumnsWidths(state, payload) {
            for (let key in payload) {
                state.resizableColumnsCache.summaryDesktopColumnsWidths[key] = payload[key]
            }
        },

        updateWorkerSummaryDesktopColumnsWidths(state, payload) {
            for (let key in payload) {
                state.resizableColumnsCache.workerSummaryDesktopColumnsWidths[key] = payload[key]
            }
        },

        updateTimesheetsView(state, payload) {
            state.timesheetsView = payload
        },

        /**
         * Replaces currentPage in state
         * @param {*} state Provided by Vuex
         * @param {Object} payload Payload will replace currentPage in state
         */
        updateCurrentPageCache(state, payload) {
            payload.cachedAt = new Date();
            state.currentPage = payload;
        },

        /**
         * Replaces currentProjects in state
         * @param {*} state Provided by Vuex
         * @param {Object} payload Payload will replace currentProjects in state
         */
        updateCurrentProjectsCache(state, payload) {
            payload.chachedAt = new Date();
            state.currentProjectsCache = payload;
        },

        /**
         * Replaces filters in state in state
         * @param {*} state Provided by Vuex
         * @param {Object} payload Required format (all values are optional, if not given they will be ignored):
         * {
         * 		[searchValue]: {String},
         * 		[project]: {String},
         * 		[page]: {Number}
         * }
         */
        updateFilters(state, payload) {
            let fields = ["searchValue", "project", "worker", "page", "perPage", "shifts", "tags", "tagsType", "state"];
            for (let i = 0; i < fields.length; i++) {
                if (typeof(payload[fields[i]]) !== 'undefined') {
                    state.filters[fields[i]] = payload[fields[i]];
                }
            }
        },

        updateCachedValues(state, payload) {
            let fields = ["date"];
            for (let i = 0; i < fields.length; i++) {
                if (typeof(payload[fields[i]]) !== 'undefined') {
                    state.cachedValues[fields[i]] = payload[fields[i]];
                }
            }
        }
    },

    actions: {

        /**
         * Get projects list with user's permissions to those projects
         * @param {*} param0  Provided by Vuex
         * @returns List of projects and permissions to them
         */
        async getProjects({ commit, rootGetters }) {
            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/projects/",
                    headers: headers
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let newCache = adaptProjectsAPIResponse(response.data);

            commit("updateCurrentProjectsCache", newCache);

            return newCache;
        },

        async getTags({ rootGetters }) {
            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/tags/",
                    headers: headers
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let tags = adaptTagsAPIResponse(response.data);

            return tags;
        },

        async getShifts({ rootGetters }) {
            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/shifts/",
                    headers: headers
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let shifts = adaptShiftsAPIResponse(response.data);

            return shifts;
        },

        /**
         * Get calendars list
         * @param {*} param0  Provided by Vuex
         * @returns List of calendars with periods
         */
        async getCalendars({ rootGetters }) {
            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/calendars/",
                    headers: headers
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let newCache = adaptCalendarsAPIResponse(response.data);

            // commit("updateCurrentProjectsCache", newCache);

            return newCache;
        },

        /**
         * Get list of workers summaries for selected time period
         * @param {*} param0  Provided by Vuex
         * @returns List of workers with summaries
         */
        async getPeriodSummary({ state, commit, rootGetters }, payload) {
            let requiredFields = [
                "calendarId", "periodIdentifier"
            ];
            let optionalFields = [
                ["page", null],
                ["perPage", 10],
                ["worker", null],
                ["project", undefined],
            ];
            let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);

            if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

            let data = tmp.validatedData;
            if (data.page == null) {
                if (state.filters.page != null) {
                    data.page = state.filters.page;
                } else {
                    data.page = 1;
                }
            }
            if (typeof(data.project) === 'undefined') {
                if (state.filters.project != null) {
                    data.project = state.filters.project;
                } else {
                    data.project = null;
                }
            }
            if (typeof(data.perPage) === 'undefined') {
                if (state.filters.perPage != null) {
                    data.search = state.filters.perPage;
                } else {
                    data.perPage = null;
                }
            }

            // Cache new values
            commit("updateFilters", {
                page: data.page,
                perPage: data.perPage,
                project: data.project,
                worker: data.worker,
            });

            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/period-details/",
                    headers: headers,
                    params: {
                        calendar_id: data.calendarId,
                        period_identifier: data.periodIdentifier,
                        page: data.page,
                        per_page: data.perPage,
                        worker: data.worker,
                        project: data.project,
                    }
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let newCache = adaptPeriodSummaryAPIResponse(response.data);

            // commit("updateCurrentProjectsCache", newCache);

            return newCache;
        },

        async getDailyPeriodSummary({ state, commit, rootGetters }, payload) {
            let requiredFields = [
                "calendarId", "periodIdentifier",
            ];
            let optionalFields = [
                ["worker", null],
                ["project", undefined],
            ];
            let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);

            if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

            let data = tmp.validatedData;

            if (typeof(data.project) === 'undefined') {
                if (state.filters.project != null) {
                    data.project = state.filters.project;
                } else {
                    data.project = null;
                }
            }

            // Cache new values
            commit("updateFilters", {
                project: data.project,
                worker: data.worker,
            });

            let headers = rootGetters.standardRequestHeaders;
            let response = null;



            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/daily-details/",
                    headers: headers,
                    params: {
                        calendar_id: data.calendarId,
                        period_identifier: data.periodIdentifier,
                        worker: data.worker,
                        project: data.project,
                    }
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let newCache = adaptDailyPeriodSummaryAPIResponse(response.data);

            return newCache;
        },
        /**
         * Calculate selected period summary for selected workers and projects
         * @param {*} param0 
         * @param {Object} payload Required format
         * {

         * } 
         *
         * @returns Id and status of summary
         */
        async calculatePeriodSummary({ rootGetters }, payload) {
            let requiredFields = [
                "workerIds", "projectIds", "calendarId", "periodIdentifier"
            ];
            let tmp = validateRequiredAndOptional(payload, requiredFields);

            if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

            let data = tmp.validatedData;
            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "POST",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/period-details/",
                    headers: headers,
                    data: {
                        worker_ids: data.workerIds,
                        project_ids: data.projectIds,
                        calendar_id: data.calendarId,
                        period_identifier: data.periodIdentifier
                    }
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            // let adaptedCalculatedWorkers = adaptPeriodSummaryAPIResponse(response.data);

            // return adaptedCalculatedWorkers;
            return response.data
        },

        async getCalculatedSummaryStatus({ rootGetters }, payload) {
            let requiredFields = [
                "summaryId"
            ];
            let tmp = validateRequiredAndOptional(payload, requiredFields);

            if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/details-status/" + tmp.validatedData.summaryId,
                    headers: headers,
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            // let adaptedResponse = adaptDailyPeriodSummaryAPIResponse(response.data);

            // return adaptedResponse;
            return response.data
        },

        async calculateDailySummary({ rootGetters }, payload) {
            let requiredFields = [
                "workerIds", "projectIds", "calendarId", "periodIdentifier"
            ];
            let tmp = validateRequiredAndOptional(payload, requiredFields);

            if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

            let data = tmp.validatedData;
            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "POST",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/daily-details/",
                    headers: headers,
                    data: {
                        worker_ids: data.workerIds,
                        project_ids: data.projectIds,
                        calendar_id: data.calendarId,
                        period_identifier: data.periodIdentifier
                    }
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            // let adaptedResponse = adaptDailyPeriodSummaryAPIResponse(response.data);

            // return adaptedResponse;
            return response.data
        },


        /**
         * Get state list
         * @param {*} param0  Provided by Vuex
         * @returns
         */
        async getStates({ rootGetters }) {
            let headers = rootGetters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "GET",
                    url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/transitions-matrix/",
                    headers: headers
                });
            } catch (error) {
                throw {
                    errorType: "request",
                    error: error
                };
            }

            let adapted = __adaptStates(response.data);
            return adapted
        },      
    }
}