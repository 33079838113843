import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import jwt_decode from 'jwt-decode'

import TenantsModule from "./modules/tenants"
import ImportTimesheetsModule from "./modules/importTimesheets"
import TimesheetsModule from "./modules/timesheets"
import ReportsModule from "./modules/reports"
import CurrentPresenceModule from "./modules/currentPresence"
import ExportTimesheetsModule from "./modules/exportTimesheets"
import WorkersModule from "./modules/workers"
import LeavesModule from "./modules/leaves"
import RCPRecords from "./modules/rcpRecords"
import Schedules from "./modules/schedules"
import ApprovalProcess from "./modules/approvalProcess"
import LeaveTypeApprovalProcess from "./modules/leaveTypeApprovalProcess"
import Calendars from "./modules/calendars"

import appConfig from '@/app_config'
import validateRequiredAndOptional from '@/customFunctions/payloadValidation'
import axios from 'axios'
import VueI18n from '@/i18n.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        importTimesheets: ImportTimesheetsModule,
        timesheets: TimesheetsModule,
        reports: ReportsModule,
        exportTimesheets: ExportTimesheetsModule,
        workers: WorkersModule,
        leaves: LeavesModule,
        rcpRecords: RCPRecords,
        currentPresence: CurrentPresenceModule,
        tenants: TenantsModule,
        schedules: Schedules,
        approvalProcess: ApprovalProcess,
        leaveTypeApprovalProcess: LeaveTypeApprovalProcess,
        calendars: Calendars,
    },

    plugins: [createPersistedState()],
    state: {
        historyDrawerPin: false,
        accessToken: null,
        refreshToken: null,

        timesheetsCache: {
            page: 1,
            selectedProject: -1,
            search: null,
            itemsPerPage: 10
        },
        workersCache:{
            columnsVisible: {
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
                7: true,
                8: true,
                9: true,
                10: true,
                11: true,
            },
            page: 1,
            itemsPerPage: 10,
            selectedProject: 0,
            sortField: 'last_name',
            sortType: 'asc',
            search: null,
            status: 0,
            department: -1,
            tags: null,
            tagsType: 0,
            shifts: null,
        },
        usersCache:{
            columnsVisible: [],
            page: 1,
            itemsPerPage: 10,
            dataAccess: -1,
            search: null,
            status: -1,
        }
    },
    getters: {
        accessToken: (state) => {
            return state.accessToken;
        },

        standardRequestHeaders: (state) => {
            let jwt = state.accessToken;

			let headers = {
                "Content-Type": "application/json",
                "Accept-Language": VueI18n.locale,
            };
			if(jwt != null){
				headers["Authorization"] = "Bearer " + jwt;
			}
            if(appConfig.apiVersion != null && appConfig.apiVersion != ""){
                headers["xCiApiVersion"] = appConfig.apiVersion;
            }

            return headers;
        },

        isLoggedIn: (state) => {
            if(state.accessToken != '' && state.accessToken != null && typeof(state.accessToken) !== 'undefined'){
                let decoded;
                try{
                    decoded = jwt_decode(state.accessToken);
                } catch(error){
                    return false;
                }
                let exp = decoded.exp;
                if ((exp - (Date.now() / 1000)) < 0) {
                    return false;
                } else
                return true;
            }
            else{
                return false;
            }
         },
    },
    mutations: {
        updateHistoryDrawlerPin(state) {
            state.historyDrawerPin = !state.historyDrawerPin
        },

        logout (state) {
            state.accessToken = null;
            state.refreshToken = null;
        },

        setTokens (state, payload) {
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
        },

        updateTimesheetsCache(state, payload) {
            state.timesheetsCache.page = payload.page;
            state.timesheetsCache.selectedProject = payload.project;
            state.timesheetsCache.search = payload.search;
            state.timesheetsCache.itemsPerPage = payload.itemsPerPage
        },
        updateWorkersCache(state, payload) {
            state.workersCache.page = payload.page;
            state.workersCache.selectedProject = payload.project;
            state.workersCache.search = payload.search;
            state.workersCache.status = payload.status;
            state.workersCache.department = payload.department;
            state.workersCache.sortField = payload.sortField;
            state.workersCache.sortType = payload.sortType;
            state.workersCache.itemsPerPage = payload.itemsPerPage
            for(let key in payload.columnsVisible){
                state.workersCache.columnsVisible[key]=payload.columnsVisible[key]
            }
            state.workersCache.tags=payload.tags
            state.workersCache.tagsType=payload.tagsType
            state.workersCache.shifts=payload.shifts
        },
        updateUsersCache(state, payload) {
            state.usersCache.page = payload.page;
            state.usersCache.dataAccess = payload.dataAccess;
            state.usersCache.search = payload.search;
            state.usersCache.status = payload.status;
            state.usersCache.itemsPerPage = payload.itemsPerPage
            state.usersCache.columnsVisible=payload.columnsVisible
            // for(let key in payload.columnsVisible){
            //     state.usersCache.columnsVisible[key]=payload.columnsVisible[key]
            // }
        },
    },
    actions: {
        /**
         * Standard error handling behaviour
         * @param {*} payload Required format:
         *  {
         *      component: <component>,
         *      error: <requestError>,
         *      [allowDefault]: {Boolean}
         *  }
         */
        /* eslint-disable */
        standardRequestErrorHandler({}, payload){
            let error = payload.error;
            let comp = payload.component;

            if(typeof(payload.allowDefault) === 'undefined'){
                payload.allowDefault = true;
            }

            if(typeof(error.response) === 'undefined'){
                comp.$emit('display-error', comp.$t('networkError'));
            }

            switch(error.response.status){
                // Translations added in locales directory, so they are always 
                // available, regardless of used component
                case 401:
                    // Redirect to login page
                    comp.$router.push("/");
                    break;
                case 403:
                    comp.$emit('display-error', comp.$t('youDoNotHavePriviledgesToDoThatOperation'));
                    break;
                case 412:
                    console.log("APP_OUTDATED");
                    comp.$emit('app-outdated');
                    break;
                case 429:
                    comp.$emit('display-error', comp.$t('tooManyRequestsError'));
                    break;
                case 400:
                    comp.$emit('display-error', comp.$t('requestRejected'));
                    break;
                case 500:
                    comp.$emit('display-error', comp.$t('internalServerError'));
                    break;
                default:
                    if(payload.allowDefault){
                        comp.$emit('display-error', comp.$t('errorOccured'));
                        break;
                    }
                    return false;
            }
            return true;
        },

        /**
         * Attempts to login user to application
         * @param {*} param0 Provided by Vuex
         * @param {Object} payload Required format
         *  {
         *      username: {String},
         *      password: {String}
         *  }
         */
        async login({getters, commit, dispatch}, payload){
            let requiredFields = [
                "username",
                "password"
            ];
            let tmp = validateRequiredAndOptional(payload, requiredFields, null);
            if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
            let data = tmp.validatedData;

            let headers = getters.standardRequestHeaders;
            let response = null;

            try {
                response = await axios({
                    method: "POST",
                    url: appConfig.getApiUrl(null) + "/api/v1/login/",
                    headers: headers,
                    data: {
                        username: data.username,
                        password: data.password
                    }
                });
            } catch(error) {
                // if(typeof(error.response) !== 'undefined'){
                //     throw {
                //         errorType: "invalid_credentials",
                //         error: error
                //     };
                // }
                // else{
                throw {
                    errorType: "request",
                    error: error
                };
                //}
            }

            let adaptedTenantsList;
            let tokens = {accessToken: null, refreshToken: null};
            let adaptedTenantData = null;
            try {
                adaptedTenantsList = await dispatch("tenants/adaptTenantsList", response.data.tenants);
                tokens.accessToken = response.data.access;
                tokens.refreshToken = response.data.refresh;

                if(typeof(tokens.accessToken) === 'undefined' || typeof(tokens.refreshToken) === 'undefined'){
                    throw "Invalid response (missing access or refresh token)";
                }

                // If only 1 tenant assigned to user, more detailed data has been retrieved, and so can be adapted and assigned as "currentTenant"
                if (adaptedTenantsList.length == 1){
                    adaptedTenantData = await dispatch("tenants/adaptTenant", response.data.tenants[0]);
                }
            } catch(error){
                throw {
                    errorType: "adapting",
                    error: error
                };
            }

            commit("tenants/clearData");
            commit("setTokens", tokens);
            commit("tenants/updateAllTenants", adaptedTenantsList);

            if(adaptedTenantData != null){
                commit("tenants/updateCurrentTenant", adaptedTenantData);
            }
        },

        logout({commit, dispatch}){
            commit("logout");
            dispatch("tenants/logout");
        }
    }
})