// Useful extension for this file: #region folding for VS Code

import axios from 'axios';
import appConfig from '@/app_config';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import structuredClone from '@ungap/structured-clone';

function adaptCalendars(data){
    let result = []

    for(let item of data){
        result.push({
            id: item.id,
            identifier: item.identifier,
            name: item.name,
            comment: item.comment,
            color: item.color,
        })
    }

    return result
}

function adaptEvents(data){
    let result = []

    for(let item of data){
        result.push({
            id: item.id,
            name: item.name,
            identifier: item.identifier,
            calendar: item.calendar_events_dictionary_id,
            description: item.description,
            color: item.color,
            dates: item.actual_dates,
			repeatType: item.period_definition_type,
			yearTo: item.pdt_1_to_year,
        })
    }

    return result
}

export default {
	namespaced: true,

	state: {
		calendarMode: 0, //Monthly

		filters: {
			calendar: null,
			month: null,
			// date: null,
            search: null
		},
	},

	getters: {
		filters: (state) => {
			return structuredClone(state.filters);
		},
		calendarMode: (state) => {
			return state.calendarMode;
		},
	},

	mutations: {
		updateCalendar(state, calendar){
			state.filters.calendar=calendar
		},
		updateMonth(state, month){
			state.filters.month=month
		},
		updateDate(state, date){
			state.filters.date=date
		},
        updateSearch(state, search){
			state.filters.search=search
		},
		updateCalendarMode(state, calendarMode){
			state.calendarMode=calendarMode
		},
	},

	actions: {
		async getCalendars({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/admin/calendar-events/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = adaptCalendars(response.data);

			return adapted;
		},

		async getCalendarEvents({rootGetters}, payload){
			let requiredFields = [
				"startDate",
				"endDate",
				"calendarId"
			];

			let optionalFields = [
				["search", null],
			]

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let params = {
				start_date: d.startDate,
				end_date: d.endDate,
			}

			if(d.search!=null){
				params.name = d.search
			}
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/calendar-events/${d.calendarId}/events/`,
					headers: headers,
					params: params
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}
			
			let adaptedEvents = adaptEvents(response.data)

			return adaptedEvents;
		},

		async addEvent({rootGetters}, payload){
			let requiredFields = ["calendarId", "name", "periodType", "date"];
			let optionalFields = [
				["description", null],
				["color", null],
				["yearTo", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			let data = {
				name: d.name,
				period_definition_type: d.periodType,
				date: d.date,
			}

			if(d.description!=null){
				data.description = d.description
			}
			if(d.color!=null){
				data.color=d.color
			}
			if(d.yearTo!=null){
				data.year_to=d.yearTo
			}

			let response = null

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/calendar-events/${d.calendarId}/events/`,
					headers: headers,
					data: data
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			return response
		},

		async editEvent({rootGetters}, payload){
			let requiredFields = ["calendarId", "eventId"];
			let optionalFields = [
				["name", null],
				["periodType", null],
				["date", null],
				["description", null],
				["color", null],
				["yearTo", undefined],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			let data = {}

			if(d.name!=null){
				data.name = d.name
			}
			if(d.periodType!=null){
				data.period_definition_type = d.periodType
			}
			if(d.date!=null){
				data.date = d.date
			}
			if(d.description!=null){
				data.description = d.description
			}
			if(d.color!=null){
				data.color=d.color
			}
			if(d.yearTo!=undefined){
				if(d.yearTo=='') data.year_to=null
				else data.year_to=d.yearTo
			}

			let response = null

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/calendar-events/${d.calendarId}/events/${d.eventId}`,
					headers: headers,
					data: data
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			return response
		},

		async deleteEvent({rootGetters}, payload){
			let requiredFields = [
				"calendarId", "eventId",
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/calendar-events/${d.calendarId}/events/${d.eventId}`,
					headers: headers,
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}
		},
	}
}