import axios from 'axios';
import appConfig from '@/app_config';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import structuredClone from '@ungap/structured-clone';


// #########################
// Payload Adapters
// #########################
// function adaptLeaveTypes(data){
// 	let result = []
	
// 	for(let item of data){
// 		result.push({
// 			id: item.id,
// 			clientId: item.client_id,
// 			name: item.name,
// 			description: item.description
// 		})
// 	}

// 	return result
// }

function adaptProjectPermissionGroup(data){
	let group = {
		groupId: data.id,
		name: data.name? data.name.slice(4): data.name,
		permissions: {}
	}

	for(let perm of data.permissions){
		let key = perm.codename.substr(7)
		group.permissions[key] = true
	}
	return group
}

// Adapting projects or departments permissions groups
function adaptPermissionGroups(data){
	let result = []

	for(let item of data){
		let temp = {
			id: item.id,
			name: item.name,
			groups: []
		}

		for(let g of item.groups){
			let group = adaptProjectPermissionGroup(g)
			temp.groups.push(group)
		}
		result.push(temp)
	}
	return result
}

// function adaptStateGroupPermissions(data, permissionGroupsDictionary){
// 	let result = []
// 	let temp

// 	for(let item of data){
// 		temp = permissionGroupsDictionary.find(i=> i.id ==item.group_id)
// 		if(temp){
// 			result.push({
// 				// id: item.id,
// 				name: temp.name,
// 				groupId: item.group_id,
// 				permissions: {
// 					view: item.perm_read,
// 					change: item.perm_update,
// 					delete: item.perm_delete,
// 					add: item.perm_create,
// 					changeState: item.perm_change_state,
// 					audit: item.perm_view_audit,
// 				}
// 			})
// 		}
// 	}

// 	return result
// }

function adaptTransitions(data){
	let result = {}
	let tmp

	for(let item of data){
		tmp = {
			id: item.id,
			from: item.state_from_id,
			to: item.state_to_id,
			name: item.name,
			translations: {
				en: item.name_en,
				pl: item.name_pl,
				de: item.name_de,
			}
		}

		if(!result[tmp.from]){
			result[tmp.from] = []
		}
		result[tmp.from].push(tmp)
	}

	return result
}

function adaptLeaveTypeDefaultConfig(data){
	let result = {}

	for(let item of data){
		if(item.project===null){
			result = {
				leavesWorkerPlanEntrypointId: item.leaves_worker_plan_entrypoint? item.leaves_worker_plan_entrypoint.id: null,
				leavesWorkerSendToAcceptanceEntrypointId: item.leaves_worker_send_to_acceptance_entrypoint? item.leaves_worker_send_to_acceptance_entrypoint.id: null,
				leavesWorkerCorrectionEntrypointId: item.leaves_worker_correction_entrypoint? item.leaves_worker_correction_entrypoint.id: null,
			}

		}
	}

	return result
}

function adaptPermGroupsDictionary(data){
	let result = []
	for(let item of data){
		result.push({
			id: item.id,
			name: item.name.slice(4)
		})
	}

	return result
}

function adaptLeaveTypesWorkflow(data){
	let result = {
		states: [],
		projects: data.projects,
		departments: data.departments,
		permGroupsDictionary: adaptPermGroupsDictionary(data.groups),
	}

	let transitionsMap = adaptTransitions(data.transitions)
	let temp
	
	for(let item of data.states){
		temp = {
			id: item.id,
			name: item.name,
			translations: {
				en: item.name_en,
				pl: item.name_pl,
				de: item.name_de
			},
			color: item.color,
			transitions: transitionsMap[item.id]? transitionsMap[item.id]: [],
			groupPermissions: {
				default: [],
				perProject: [],
			},
			projects: adaptPermissionGroups(item.projects),
			departments: adaptPermissionGroups(item.departments),
			countFor: item.count_for? item.count_for: [],
		}
		result.states.push(temp)
	}

	return result
}

export default {
	namespaced: true,

	state: {
		nodesPositions: {},
		networkProperties: {
			// 	position: {
			// 		x: 0,
			// 		y: 0
			// 	},
			// 	scale: 1
		},

		permissionGroupsDictionary: [],
	},

	getters: {
		// nodesPositions: (state) => {
		// 	return structuredClone(state.nodesPositions);
		// },
		// networkProperties: (state) => {
		// 	return structuredClone(state.networkProperties);
		// },

		leaveTypeNodesPositions: (state) => (leaveTypeId) => {
			return structuredClone(state.nodesPositions[leaveTypeId]);
		},
		leaveTypeNetworkProperties: (state) => (leaveTypeId) => {
			return structuredClone(state.networkProperties[leaveTypeId]);
		},
	},

	mutations: {
		// updateNetworkProperties(state, payload){
		// 	state.networkProperties = structuredClone(payload)
		// },
		// updateAllNodesPositions(state, payload){
		// 	for(let key in payload){
		// 		if(!state.nodesPositions[key]){
		// 			state.nodesPositions[key]={x: null, y: null}
		// 		}
		// 		state.nodesPositions[key].x=payload[key].x
		// 		state.nodesPositions[key].y=payload[key].y
		// 	}

		// },
		// updateNodePosition(state, payload){
		// 	if(!state.nodesPositions[payload.id]){
		// 		state.nodesPositions[payload.id]={x: null, y: null}
		// 	}
		// 	state.nodesPositions[payload.id].x=payload.x
		// 	state.nodesPositions[payload.id].y=payload.y
		// },
		updateLeaveTypeNetworkProperties(state, payload){
			if(!state.networkProperties[payload.leaveTypeId]){
				state.networkProperties[payload.leaveTypeId] = {}
			}
			state.networkProperties[payload.leaveTypeId] = structuredClone(payload.properties)
		},
		updateLeaveTypeAllNodesPositions(state, payload){
			if(!state.nodesPositions[payload.leaveTypeId]){
				state.nodesPositions[payload.leaveTypeId] = {}
			}
			let temp = state.nodesPositions[payload.leaveTypeId]
			for(let key in payload.nodes){
				if(!temp[key]){
					temp[key]={x: null, y: null}
				}
				temp[key].x=payload.nodes[key].x
				temp[key].y=payload.nodes[key].y
			}

		},
		updateNodePosition(state, payload){
			if(!state.nodesPositions[payload.leaveTypeId]){
				state.nodesPositions[payload.leaveTypeId] = {}
			}

			let temp = state.nodesPositions[payload.leaveTypeId]

			if(!temp[payload.id]){
				temp[payload.id]={x: null, y: null}
			}
			temp[payload.id].x=payload.x
			temp[payload.id].y=payload.y
		},
	},

	actions: {
		async getLeaveTypes({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/admin/leave-states/workflows/leave-definitions/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response.data;
		},

		// async getPermissionGroups({ rootGetters}, payload) {
		// 	let requiredFields = ["leaveTypeId"];

		// 	let tmp = validateRequiredAndOptional(payload, requiredFields);
		// 	let d = tmp.validatedData;

		// 	if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

		// 	let headers = rootGetters.standardRequestHeaders;
		// 	let response = null;

		// 	try {
		// 		response = await axios({
		// 			method: "GET",
		// 			url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/permission-overrides/`,
		// 			headers: headers
		// 		});
		// 	} catch (error) {
		// 		throw {
		// 			errorType: "request",
		// 			error: error
		// 		};
		// 	}

		// 	let groups = adaptPermissionGroups(response.data);

		// 	return groups
		// },

		async getLeaveTypeConfig({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/configs/`,
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let config = adaptLeaveTypeDefaultConfig(response.data);

			return config;
		},

		async updateConfig({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "defaultEntryStateId"];

			let optionalFields = [
				["defaultAcceptanceStateId", undefined],
				["defaultCorrectionStateId", undefined],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = {
				leaves_worker_plan_entrypoint_id: d.defaultEntryStateId
			}

			if(typeof(d.defaultAcceptanceStateId)!='undefined'){
				data.leaves_worker_send_to_acceptance_entrypoint_id = d.defaultAcceptanceStateId
			}

			if(typeof(d.defaultCorrectionStateId)!='undefined'){
				data.leaves_worker_correction_entrypoint_id = d.defaultCorrectionStateId
			}

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/config/`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},

		async getLeaveTypesWorkflow({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}`,
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adaptedWorkflow = adaptLeaveTypesWorkflow(response.data);

			return adaptedWorkflow;
		},
		async addState({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "transitionsTo", "groupPermissions", "countFor"];

			let optionalFields = [
				["color", null],
				["name_pl", null],
				["name_de", null],
				["name_en", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = {
				name: d.name,
				transitions_to: d.transitionsTo,
				group_permissions: d.groupPermissions,
				count_for: d.countFor,
			}

			if(d.color!=null){
				data.color = d.color
			}
			if(d.name_pl!=null){
				data.name_pl = d.name_pl
			}
			if(d.name_en!=null){
				data.name_en = d.name_en
			}
			if(d.name_de!=null){
				data.name_de = d.name_de
			}

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/states/`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},
		async updateState({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "stateId", "countFor"];

			let optionalFields = [
				["color", null],
				["name_pl", null],
				["name_de", null],
				["name_en", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = {
				name: d.name,
				code: d.code,
				transition_states: d.transitions,
				group_permissions: d.groupPermissions,
				count_for: d.countFor,
			}

			if(d.color!=null){
				data.color = d.color
			}
			if(d.name_pl!=null){
				data.name_pl = d.name_pl
			}
			if(d.name_en!=null){
				data.name_en = d.name_en
			}
			if(d.name_de!=null){
				data.name_de = d.name_de
			}

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/state/${d.stateId}`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},
		async deleteState({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "stateId", "stateToId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = {}
			
			if(d.stateToId==-1){
				data.force_delete = true
			}
			else{
				data.move_leaves_to_state_id = d.stateToId
			}

			let headers = rootGetters.standardRequestHeaders;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/state/${d.stateId}`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},
		async addTransition({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "stateFrom", "stateTo"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			let data = {
				state_to_id: d.stateTo,
			}

			let response = null

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/transitions/state/${d.stateFrom}`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async updateTransition({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "transitionId"];

			let optionalFields = [
				["name", null],
				["nameEn", null],
				["namePl", null],
				["nameDe", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			let data = {}

			if(d.name!=null){
				data.name = d.name
			}
			if(d.nameEn!=null){
				data.name_en = d.nameEn
			}
			if(d.namePl!=null){
				data.name_pl = d.namePl
			}
			if(d.nameDe!=null){
				data.name_de = d.nameDe
			}

			let response = null

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/transition/${d.transitionId}`,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async deleteTransition({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "transitionId"];

			let tmp = validateRequiredAndOptional(payload, requiredFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/transition/${d.transitionId}`,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},

		// Permissions groups
		async addPermissionsGroup({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "permissions"];

			let optionalFields = [
				["stateId", null],
				["projectId", null],
				["groupId", null],
				["departmentId", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let params = {
				state_id: d.stateId,
				project_id: d.projectId,
				department_id: d.departmentId,
				group_id: d.groupId,
			}

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			let response = null

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/permission-override/`,
					headers: headers,
					params: params,
					data: d.permissions,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},
		async updatePermissionsGroup({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "permissions"];

			let optionalFields = [
				["stateId", null],
				["projectId", null],
				["departmentId", null],
				["groupId", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			let response = null

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/permission-override/`,
					headers: headers,
					data: d.permissions,
					params: {
						state_id: d.stateId,
						project_id: d.projectId,
						department_id: d.departmentId,
						group_id: d.groupId,
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
			
			let updatedGroup = d.departmentId!==null? adaptProjectPermissionGroup(response.data.department.group): adaptProjectPermissionGroup(response.data.project.group)
			return updatedGroup
		},
		async deletePermissionsGroup({ rootGetters }, payload) {
			let requiredFields = ["leaveTypeId", "stateId"];

			let optionalFields = [
				["projectId", null],
				["groupId", null],
				["departmentId", null],
			];

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let headers = rootGetters.standardRequestHeaders;
			// let response = null;

			try {
				await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/admin/leave-states/workflow/${d.leaveTypeId}/permission-override/`,
					headers: headers,
					params: {
						state_id: d.stateId,
						project_id: d.projectId,
						department_id: d.departmentId,
						group_id: d.groupId,
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
		},
	}
}