// Useful extension for this file: #region folding for VS Code

import axios from 'axios';
import appConfig from '@/app_config';
import validateRequiredAndOptional from '@/customFunctions/payloadValidation';
import structuredClone from '@ungap/structured-clone';
import createPersistedState from "vuex-persistedstate"

const MAX_PAIRING_MILISECONDS = 86400000; // 24 hours

/* #region  Adapters */

function stringToDate(dateStr) {
	// var arr = dateStr.split(/[- :]/);
	// if (arr.length <= 3)
	// 	return new Date(arr[0], arr[1]-1, arr[2], 0, 0, 0);
	// else
	// 	return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
	let d = Date.parse(dateStr);
	return d;
}

function dateToString(date) {
	let y, m, d, h, mi, s, final;
	let leadingZero;

	y = date.getFullYear();
	m = date.getMonth() + 1;
	d = date.getDate();
	h = date.getHours();
	mi = date.getMinutes();
	s = date.getSeconds();

	leadingZero = [m, d, h, mi, s];
	for (let j = 0; j < leadingZero.length; j++) {
		if (leadingZero[j] < 10) leadingZero[j] = '0' + leadingZero[j];
	}
	final = y + '-' + leadingZero[0] + '-' + leadingZero[1] + ' ' + leadingZero[2] + ':' + leadingZero[3] + ':' + leadingZero[4];

	return final;
}

// ##########################
// Page Data Payload Adapters
// ##########################

function __adaptRawHoursChild(child) {
	return {
		workTime: child.num_mins,
		numsMins: child.num_mins
	};
}

function __adaptInOutChild(child) {
	let workTime = null;
	let timeIn = null;
	let timeOut = null;

	if (child.entry_registered_at != null) {
		timeIn = stringToDate(child.entry_registered_at);
	}
	if (child.leave_registered_at != null) {
		timeOut = stringToDate(child.leave_registered_at);
	}

	if (timeIn != null && timeOut != null) {
		workTime = Math.floor(
			(Math.abs(timeOut - timeIn) / 1000) / 60
		);
	}

	return {
		workTime: workTime,
		timeEntryId: child.time_entry_id,
		entryRegisteredAt: stringToDate(child.entry_registered_at),
		entryRegisteredAtUTC: stringToDate(child.entry_registered_at_utc),
		leaveRegisteredAt: stringToDate(child.leave_registered_at),
		leaveRegisteredAtUTC: stringToDate(child.leave_registered_at_utc)
	};
}

function __adaptChild(childType, child) {
	switch (childType) {
		case "RAW_HOURS":
			return __adaptRawHoursChild(child);
		case "IN_OUT":
			return __adaptInOutChild(child);
		default:
			throw {
				"errorType": "unknown_child_type"
			};
	}
}

function __adaptDetails(details) {
	let adapted = [];
	let tmp;

	let d;
	for (let i = 0; i < details.length; i++) {
		d = details[i];
		tmp = {
			id: d.id,
			timeEntryId: d.time_entry_id,
			numMins: d.num_mins,
			type: d.type,
			manually: d.manual_input
		};
		adapted.push(tmp);
	}

	return adapted;
}

function __adaptAttributes(attributes) {
	let adapted = [];
	let tmp;

	let a;
	for (let i = 0; i < attributes.length; i++) {
		a = attributes[i];
		tmp = {
			id: a.id,
			timeEntryId: a.time_entry_id,
			name: a.name,
			code: a.code,
			value: a.value
		};
		adapted.push(tmp);
	}

	return adapted;
}

function __adaptDays(timesheets, drafts, periodStart, periodEnd) {
	let adapted = [];
	let pStart = stringToDate(periodStart);
	let pEnd = stringToDate(periodEnd);

	// Iterate through dates, however limit it to 60 iterations to avoid endless loops
	let counter = 0;
	for (let d = new Date(pStart); d <= pEnd; d.setDate(d.getDate() + 1)) {
		adapted.push({
			date: new Date(d),
			timesheets: [],
			drafts: [],
			acceptedDraftsPairs: [],
			leaves: [],
			projects: {}
		});
		counter++;
		// 60 is far past anything we're handling in this view
		// Now it is 366, because in approval view we can handle data from whole year
		// throw an exception
		if (counter > 366) {
			throw {
				errorType: "data_overload"
			};
		}
	}
	if (counter == 0) {
		throw {
			errorType: "invalid_range"
		};
	}

	let tmp = null;
	let index = 0;
	let compare = adapted[0].date;

	// Iterate through timesheets
	let t;
	let tDate;
	let timesheetsDayIndexes = {}
	for (let i = 0; i < timesheets.length; i++) {
		t = timesheets[i];
		tDate = stringToDate(t.date);

		while (compare != null && compare < tDate) {
			index++;
			compare = (adapted.length > index) ? adapted[index].date : null;
		}
		if (compare == null) break;

		tmp = {
			id: t.id,
			workerId: t.worker_id,
			projectId: t.project_id,
			stateId: t.state_id,
			shiftId: t.shift_id,
			date: t.date,
			details: null,
			childType: t.child_type,
			child: null,
			comment: t.comment,
			category: t.category? t.category.name: null,

			add: t.add,
			view: t.view,
			change: t.change,
			delete: t.delete,
			viewAudit: t.view_audit,
			changeState: t.change_state
		};

		tmp.details = __adaptDetails(t.details);
		tmp.attributes = __adaptAttributes(t.attributes);
		tmp.child = __adaptChild(t.child_type, t.child);

		timesheetsDayIndexes[tmp.id] = index

		adapted[index].timesheets.push(tmp);
	}

	// Iterate through drafts
	let draftsRefList = []; // Stores direct references to drafts that are about to be reorganized into pairs per project
	let draftsPerProject = {};
	let draft, respDraft, draftMeta;
	let acceptedDraftsPairs = {}

	// Adapting drafts and grouping them by project (original order stored in draftsRefList, and it will later be used for organizing the order in each day group)
	for (let i = 0; i < drafts.length; i++) {
		respDraft = drafts[i];
		draft = {
			id: respDraft.id,
			workerId: respDraft.worker_id,
			projectId: respDraft.project_id,
			registeredAt: stringToDate(respDraft.registered_at),
			registeredAtUTC: stringToDate(respDraft.registered_at_utc),
			registeredAtOriginal: stringToDate(respDraft.registered_at_original),
			type: respDraft.type,
			relatedTimeEntryId: respDraft.related_time_entry_id,
			add: respDraft.add,
			view: respDraft.view,
			change: respDraft.change,
			delete: respDraft.delete
		}

		// Accepted drafts
		if(draft.relatedTimeEntryId!=null){
			let rtmId = draft.relatedTimeEntryId
			if(!acceptedDraftsPairs[rtmId]){
				acceptedDraftsPairs[rtmId] = {
					entry: null,
					leave: null,
					index: timesheetsDayIndexes[rtmId]!==undefined? timesheetsDayIndexes[rtmId]: -1,
				}
			}
			if(draft.type==0){
				acceptedDraftsPairs[rtmId].entry = draft
			}
			else if(draft.type==1){
				acceptedDraftsPairs[rtmId].leave = draft
			}

			continue;
		}

		// Stores meta data about draft
		draftMeta = {
			// Original draft
			draft: draft,

			// Meta-data
			dayIndex: null
		}

		draftsRefList.push(draftMeta);
		if (typeof(draftsPerProject[draft.projectId]) === 'undefined') {
			draftsPerProject[draft.projectId] = [];
		}
		draftsPerProject[draft.projectId].push(draftMeta);
	}

	// Determining day index per accepted drafts
	// for(let key in acceptedDraftsPairs){
	//	 let ts = adapted.timesheets.find(i=> i.id == key)
	//	 compare = adapted[0].date;
	//	 while (compare != null && compare < tDate) {
	//		 index++;
	//		 compare = (adapted.length > index) ? adapted[index].date : null;
	//	 }
	//	 if (compare == null) break;
	// }
	


	// Determining day index per each non -accepted draft
	let lastDraftMeta = null;
	let currentDraftMeta = null;
	let dDate;
	// let currentPair = {
	//	 entry: null,
	//	 leave: null,
	//	 index: null,
	// }
	// let prevIndex = null;
	// let prevCompare = null;
	

	for (let projectId in draftsPerProject) {
		index = 0;
		compare = adapted[0].date; // first date in time period range
		lastDraftMeta = null;
		currentDraftMeta = null;

		for (let i = 0; i < draftsPerProject[projectId].length; i++) {
			currentDraftMeta = draftsPerProject[projectId][i];
		
			// Pairing with previous record
			if (
				lastDraftMeta != null &&
				lastDraftMeta.draft.type == 0 &&
				currentDraftMeta.draft.type == 1 &&
				(Math.floor(
					(Math.abs(lastDraftMeta.draft.registeredAt - currentDraftMeta.draft.registeredAt) / 1000) / 60
				)) < MAX_PAIRING_MILISECONDS
			) {
				if(lastDraftMeta.draft.registeredAt.toString('yyyy-MM-dd')<periodStart.toString('yyyy-MM-dd')){
					currentDraftMeta.dayIndex=-1
					// lastDraftMeta.dayIndex=-1
				}
				else currentDraftMeta.dayIndex = index;
			} 
			else if(currentDraftMeta.draft.registeredAt.toString('yyyy-MM-dd')<periodStart.toString('yyyy-MM-dd')){
				currentDraftMeta.dayIndex=-1
			} 
			// else if(currentDraftMeta.draft.registeredAt.toString('yyyy-MM-dd')>periodStart.toString('yyyy-MM-dd')){
			//	 currentDraftMeta.dayIndex=-1
			// } 
			else {
				let tmpDate = currentDraftMeta.draft.registeredAt;
				dDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 0, 0, 0); // Removing time from the datetime
				// prevIndex = index;
				// prevCompare = compare;
				while (compare != null && compare < dDate) {
					index++;
					compare = (adapted.length > index) ? adapted[index].date : null;
				}
				// if(index - prevIndex == 1 && currentDraftMeta.draft.type == 1){
				//	 index = prevIndex;
				//	 compare = prevCompare;
				// }
				if (compare == null) break;

				

				currentDraftMeta.dayIndex = index;
			}

			lastDraftMeta = currentDraftMeta;
		}
	}
	// Day Indexes have been assigned, finally append all drafts to apropriate days
	for (let i = 0; i < draftsRefList.length; i++) {
		if (draftsRefList[i].dayIndex != null) {
			if(draftsRefList[i].dayIndex!=-1)adapted[draftsRefList[i].dayIndex].drafts.push(draftsRefList[i].draft);
		}
	}
	
	for(let key in acceptedDraftsPairs){
		let pair = acceptedDraftsPairs[key]
		if(pair.index!=null && pair.index!=-1){
			// if(pair.entry) adapted[pair.index].drafts.push(pair.entry)
			// if(pair.leave) adapted[pair.index].drafts.push(pair.leave)
		
			adapted[pair.index].acceptedDraftsPairs.push(pair)
		}
	}

	// Sorting time entries
	for(let item of adapted){
		item.timesheets.sort((a,b) => {
			if(a.childType=='IN_OUT' && b.childType=='IN_OUT'){
				if(a.child.entryRegisteredAt < b.child.entryRegisteredAt) return -1
				if(a.child.entryRegisteredAt == b.child.entryRegisteredAt){
					if(a.child.leaveRegisteredAt < b.child.leaveRegisteredAt) return -1
					if(a.child.leaveRegisteredAt == b.child.leaveRegisteredAt) return 0
					return 1
				}
				return 1
			}
			if(a.childType=='RAW_HOURS') return 1
			if(b.childType=='RAW_HOURS') return -1
			return 1
		})
	}
	return adapted;
}

function __assignLeavesToDays(adaptedWorkerDays, adaptedWorkerLeaves) {
	let d, l;

	for (let i = 0; i < adaptedWorkerDays.length; i++) {
		d = adaptedWorkerDays[i];
		for (let j = 0; j < adaptedWorkerLeaves.length; j++) {
			l = adaptedWorkerLeaves[j];

			if (d.date >= l.dateFrom && d.date <= l.dateTo) {
				d.leaves.push(l);
			}
		}
	}
}

function __adaptWorkerLeaves(leaves) {
	let adapted = [];

	let tmp;
	let l;
	for (let i = 0; i < leaves.length; i++) {
		l = leaves[i];
		tmp = {
			id: l.id,
			status: l.status,
			workerId: l.worker_id,
			projectId: l.project_id,
			leaveType: {
				id: l.leave_type.id,
				code: l.leave_type.code,
				name: l.leave_type.name,
				description: (l.leave_type.description != null && l.leave_type.description != "") ? l.leave_type.description : null,
				color: l.leave_type.color,
				bgColor: l.leave_type.bgcolor
			},
			dateFrom: stringToDate(l.date_from),
			dateTo: stringToDate(l.date_to),
		};
		adapted.push(tmp);
	}
	return adapted;
}

function __adaptWorkerShifts(shifts) {
	let adapted = [];

	let tmp;
	let s;
	for (let i = 0; i < shifts.length; i++) {
		s = shifts[i];
		tmp = {
			id: s.id,
			shiftId: s.shift_id,
			shiftName: s.name
		};
		adapted.push(tmp);
	}
	return adapted;
}

function __adaptWorker(workerResponse, periodStart, periodEnd) {
	let r = workerResponse;
	let adapted = {
		id: r.id,
		firstName: r.personal_data.first_name,
		lastName: r.personal_data.last_name,
		days: null,
		projects: null,
		leaves: null,
		tags: r.tags,
		shifts: null,
		permissions: r.drafts_perm,
	};

	adapted.shifts = __adaptWorkerShifts(r.shifts);
	adapted.days = __adaptDays(r.timesheets, r.drafts, periodStart, periodEnd);
	adapted.leaves = __adaptWorkerLeaves(r.leaves);
	__assignLeavesToDays(adapted.days, adapted.leaves);

	return adapted;
}

function __adaptWorkers(workersResponse, periodStart, periodEnd) {
	let adapted = [];

	for (let i = 0; i < workersResponse.length; i++) {
		adapted.push(__adaptWorker(workersResponse[i], periodStart, periodEnd));
	}

	return adapted;
}

function __adaptEvents(events){
	let adapted = []

	for(let item of events){
		adapted.push({
			id: item.id,
			name: item.name,
			color: item.color,
			calendarId: item.calendar_events_dictionary_id,
			calendarColor: item.calendar_color,
			dates: item.actual_dates,
			projectIds: item.project_ids,
		})
	}

	return adapted
}

function adaptCombinedEntriesAPIResponse(apiResponse, periodStart, periodEnd) {
	let adapted = {
		page: apiResponse.page,
		perPage: apiResponse.per_page,
		numPages: apiResponse.num_pages,
		totalItems: apiResponse.total_items,
		startDate: periodStart,
		endDate: periodEnd,
		workers: null,
		events: null,
	};

	adapted.workers = __adaptWorkers(apiResponse.workers, periodStart, periodEnd);
	adapted.events = __adaptEvents(apiResponse.events);

	return adapted;
}

// #########################
// Calendars Payload Adapters
// #########################

function __adaptPeriods(periods) {
	let adapted = [];

	for(let i = 0; i < periods.length; i++){
		adapted.push({
			calendarId: periods[i].calendar_id,
			// id: periods[i].id,
			identifier: periods[i].identifier,
			type: periods[i].type,
			startDate: periods[i].start_date,
			endDate: periods[i].end_date
		});
	}

	return adapted;
}

/** OLD VERSION */
// function __adaptPeriods(yearsPeriods) {
//	 console.log(yearsPeriods)
//	 let result = {
//		 weeks: [],
//		 months: [],
//		 quarters: [],
//		 years: [],
//	 }


//	 let types = ["weeks", "months", "quarters"]

//	 for (let year of yearsPeriods) {
//		 for (let i = 0; i < types.length; i++) {
//			 let type = types[i]
//			 for (let period of year[type]) {
//				 let tmp = {
//					 id: period.id,
//					 calendarIndex: period.calendar_index,
//					 periodIndex: period.period_index,
//					 startDate: period.start_date,
//					 endDate: period.end_date
//				 }

//				 result[type].push(tmp)
//			 }
//		 }

//		 let y = year.year
//		 result.years.push({
//			 id: y.id,
//			 calendarIndex: y.calendar_index,
//			 periodIndex: y.period_index,
//			 startDate: y.start_date,
//			 endDate: y.end_date
//		 })
//	 }

//	 return result
// }

function adaptCalendarsAPIResponse(apiResponse) {
	let adapted = [];
	let tmp, p;

	for (let i = 0; i < apiResponse.length; i++) {
		p = apiResponse[i];
		tmp = {
			id: p.id,
			identifier: p.identifier,
			name: p.name,
			comment: p.comment,
			weeks: null,
			months: null,
			quarters: null,
			years: null
		};

		tmp.weeks = __adaptPeriods(p.weeks);
		tmp.months = __adaptPeriods(p.months);
		tmp.quarters = __adaptPeriods(p.quarters);
		tmp.years = __adaptPeriods(p.years);

		adapted.push(tmp);
	}
	return adapted;
}

/**OLD VERSION */
// function adaptCalendarsAPIResponse(apiResponse) {
//	 console.log(apiResponse)
//	 let adapted = [];
//	 let tmp, p;

//	 for (let i = 0; i < apiResponse.length; i++) {
//		 p = apiResponse[i];
//		 tmp = {
//			 id: p.id,
//			 key: p.key,
//			 name: p.name,
//			 periods: null
//		 };

//		 let adaptedPeriods = __adaptPeriods(p)
//		 tmp.periods = adaptedPeriods


//		 adapted.push(tmp);
//	 }
//	 return adapted;
// }


// #########################
// Projects Payload Adapters
// #########################
function adaptProjectsAPIResponse(apiResponse) {
	let adapted = [];
	let tmp, p;

	for (let i = 0; i < apiResponse.length; i++) {
		p = apiResponse[i];
		tmp = {
			id: p.id,
			name: p.name,
			timesheetsPermissions: {
				add: p.timesheets_perm.add,
				view: p.timesheets_perm.view,
				change: p.timesheets_perm.change,
				delete: p.timesheets_perm.delete,
				changeState: p.timesheets_perm.change_state,
				viewAudit: p.timesheets_perm.view_audit
			},
			draftsPermissions: {
				add: p.drafts_perm.add,
				view: p.drafts_perm.view,
				change: p.drafts_perm.change,
				delete: p.drafts_perm.delete
			}
		};
		adapted.push(tmp);
	}
	adapted.sort((a,b) => {
		if(a.name < b.name) return -1
		if(a.name == b.name) return 0
		return 1
	})
	return adapted;
}

// #########################
// Shifts Payload Adapters
// #########################
function adaptShiftsAPIResponse(apiResponse) {
	let adapted = [];

	for (let i = 0; i < apiResponse.length; i++) {
		let p = apiResponse[i];
		adapted.push({
			id: p.id,
			name: p.name
		})
	}
	return adapted;
}

// #########################
// Tags Payload Adapters
// #########################
function adaptTagsAPIResponse(apiResponse) {
	let adapted = [];

	for (let i = 0; i < apiResponse.length; i++) {
		let p = apiResponse[i];
		adapted.push({
			id: p.id,
			name: p.choice_name,
			color: p.color
		})
	}
	return adapted;
}


// ##########################
// State Payload Adapters
// ##########################
function __adaptStates(apiResponse) {
	let adapted = {
		states: [],
		// transitionsMatrix: [],
	};
	let tmp, p;

	for (let i = 0; i < apiResponse.states.length; i++) {
		p = apiResponse.states[i];
		tmp = {
			id: p.id,
			name: p.name,
			readonly: p.readonly,
			default: p.default,
			code: p.code,
			color: p.color,
			projectId: p.project_id,
			view: p.view,
			add: p.add,
			change: p.change,
			delete: p.delete,
			changeState: p.change_state,
			viewAudit: p.view_audit,
			transitions: []
		};
		adapted.states.push(tmp);
	}

	for (let i = 0; i < apiResponse.transitions_matrix.length; i++) {
		p = apiResponse.transitions_matrix[i];
		let idx = adapted.states.findIndex(i => i.id==p.state_id)
		if(idx!=-1){
			for(let item of p.target_state_ids){
				let stateTo = adapted.states.find(i => i.id == item.target_state_id)

				if(stateTo){
					adapted.states[idx].transitions.push({
						name: item.name,
						stateToId: stateTo.id,
						stateToName: stateTo.name,
						stateToColor: stateTo.color,
						stateToChangeState: stateTo.changeState,
					})
				}
			}
			
			// if(stateTo){
			//	 adapted.states[idx].transitions = p.target_state_ids
			// }
		}
		// tmp = {
		//	 stateId: p.state_id,
		//	 targetStateIds: p.target_state_ids
		// };
		// adapted.transitionsMatrix.push(tmp);
	}
	return adapted;
}


/* #endregion */

export default {
	namespaced: true,

	plugins: [createPersistedState()],

	state: {
		recordDetailsTimesheetSectionColumnsVisibility: {
			entry: true,
			leave: true,
			totalHours: true,
			dayHours: true,
			nightHours: true,
			breakHours: true,
			overHours: true,
			holidayHours: true,
			comment: true
		},

		resizableColumnsCache: {
			desktopColumnsWidths: [
				200, // full name
				120, // 7 date columns
				120,
				120,
				120,
				120,
				120,
				120,
				120, // Summary column
				200, //Tags column
				200, //Shifts column
			],
			desktopColumnsVisible: {
				0: true,
				1: true,
				2: true,
				3: true,
				4: true,
				5: true,
				6: true,
				7: true,
				8: true,
				9: false,
				10: false,
				11: false,
				12: false,
				13: false,
				14: false,
			},
			summaryDesktopColumnsWidths: [
				200, // full name
				120, // Summary column
				120, //6 summary details column
				120,
				120,
				120,
				120,
				120,
			],

			workerSummaryDesktopColumnsWidths: [
				200, // full name
				120, // Working hours column
				120, // Summary column
				120, //6 summary details column
				120,
				120,
				120,
				120,
				120,
			],
		},

		currentProjects: null,
		// [
		// 	{
		// 		id: {Number},
		// 		name: {String},
		// 		add: {Boolean},
		// 		view: {Boolean},
		// 		change: {Boolean},
		// 		delete: {Boolean},
		// 		changeState: {Boolean}
		// 	}
		// ],

		timesheetsView: 'hours',

		timesheetsMode: {
			mode: 'currentWeek',
			periodStartDate: {},
		},

		cachedValues: {
			date: null,
			calendarId: null,
			periodId: null,
			periodType: null,
			periodStartDate: null,
		},

		filters: {
			searchValue: null,
			project: -1,
			worker: null,
			page: null,
			perPage: null,
			shifts: null,
			tagsType: 0,
			tags: null,
			state: -1,
		},

		timesheetsFilters: {
			searchValue: null,
			project: -1,
			worker: null,
			page: null,
			perPage: null,
			shifts: null,
			tagsType: 0,
			tags: null,
			state: -1,
		},

		acceptanceFilters: {
			project: null,
			worker: null,
			page: null,
			perPage: null,
			state: -1,
		},



		copyRecordsOptions: {
			dataType: 'ALL',
			cycleType: 0, //Daily
			dailyType: 1, //Weekdays
			everyXDaysNumber: 1,
		},

		currentPage: null,
		/* #region  CurrentPage format */
		// {
		// 	page: 1,
		// 	numPages: 2,
		// 	itemsPerPage: 10,
		//
		// 	workers: [
		// 		{
		// 			id: {Number},
		// 			firstName: {String},
		// 			lastName: {String},
		// 			days: [
		// 				{
		// 					date: {Date},
		// 					leaves: [
		// 						{
		// 							id: {Number}, // leave assignment id
		// 							status: {Number},
		// 							workerId: {Number},
		// 							projectId: {Number},
		// 							leaveType: {
		// 								id: {Number},
		// 								code: {String},
		// 								name: {String},
		//								description: {String},
		// 								color: {String},
		// 								bgColor: {String}
		// 							},
		// 						}
		// 					],
		// 					timesheets: [
		// 						{
		// 							id: {Number},
		// 							workerId: {Number},
		// 							projectId: {Number},
		// 							stateId: {Number},
		// 							shiftId: {Number},
		// 							date: {Date},
		// 							details: [
		// 								{
		// 									id: {Number},
		// 									timeEntryId: {Number},
		// 									numMins: {Number},
		// 									type: {Number}
		// 								}
		//							],
		// 							attributes: [
		// 								{
		// 									id: {Number},
		// 									timeEntryId: {Number},
		// 									name: {String},
		// 									code: {String},
		// 									value: {String}
		// 								}
		// 							],
		//
		// 							childType: "RAW_HOURS/IN_OUT",
		// 							child: {
		// 								workTime: {Number}, // Work time in minutes
		// 								timeEntryId: {Number},
										
		// 								// RAW_HOURS
		// 								numMins: {Number},

		// 								// IN_OUT
		// 								entryRegisteredAt: {Date},
		// 								entryRegisteredAtUTC: {Date},
		// 								leaveRegisteredAt: {Date},
		// 								leaveRegisteredAtUTC: {Date}
		// 							},

		// 							add: true,
		// 							view: true,
		// 							change: true,
		// 							delete: true,
		// 							viewAudit: true,
		// 							changeState: true
		// 						}
		// 					],
		// 					drafts: [
		// 						{
		// 							id: {Number},
		// 							workerId: {Number},
		// 							projectId: {Number},
		// 							registeredAt: {Date},
		// 							registeredAtUTC: {Date},
		// 							type: {Number},

		// 							add: true,
		// 							view: true,
		// 							change: true,
		// 							delete: true
		// 						}
		// 					],
		// 				}
		// 			],
		// 			leaves: [
		// 				{
		// 					id: {Number}, // leave assignment id
		// 					status: {Number},
		// 					workerId: {Number},
		// 					projectId: {Number},
		// 					leaveType: {
		// 						id: {Number},
		// 						code: {String},
		// 						name: {String},
		//						description: {String},
		// 						color: {String},
		// 						bgColor: {String}
		// 					},
		// 					dateFrom: {Date},
		// 					dateTo: {Date}
		// 				}
		// 			],
		// 		}
		// 	]
		// }
		/* #endregion */

	},

	getters: {
		recordDetailsTimesheetSectionColumnsVisibility: (state) => {
			return state.recordDetailsTimesheetSectionColumnsVisibility;
		},

		desktopColumnsWidths: (state) => {
			return state.resizableColumnsCache.desktopColumnsWidths;
		},
		desktopColumnsVisible: (state) => {
			return state.resizableColumnsCache.desktopColumnsVisible;
		},
		summaryDesktopColumnsWidths: (state) => {
			return state.resizableColumnsCache.summaryDesktopColumnsWidths;
		},
		workerSummaryDesktopColumnsWidths: (state) => {
			return state.resizableColumnsCache.workerSummaryDesktopColumnsWidths;
		},
		currentPageCache: (state) => {
			return structuredClone(state.currentPage);
		},
		currentProjectsCache: (state) => {
			return structuredClone(state.currentProjects);
		},
		filters: (state) => {
			return structuredClone(state.filters);
		},
		timesheetsFilters: (state) => {
			return structuredClone(state.timesheetsFilters);
		},
		acceptanceFilters: (state) => {
			return structuredClone(state.acceptanceFilters);
		},
		copyRecordsOptions: (state) => {
			return structuredClone(state.copyRecordsOptions);
		},
		cachedValues: (state) => {
			return structuredClone(state.cachedValues);
		},
		timesheetsView: (state) => {
			return state.timesheetsView;
		},
		timesheetsMode: (state) => {
			return state.timesheetsMode;
		}
	},

	mutations: {
		/**
		 * Updates cached columns visibility values
		 * @param {*} state Provided by Vuex
		 * @param {Object} payload Required format (all fields optional)
		 * 	{
		 * 		[entry]: {Boolean},
		 * 		[leave]: {Boolean},
		 * 		[totalHours]: {Boolean},
		 * 		[dayHours]: {Boolean},
		 * 		[nightHours]: {Boolean},
		 * 		[breakHours]: {Boolean},
		 * 		[overHours]: {Boolean},
		 * 		[holidayHours]: {Boolean},
		 * 		[comment]: {Boolean}
		 * 	}
		 */
		updateRecordDetailsTimesheetSectionColumnsVisibility(state, payload){
			let fieldsMapping = [
				// [<state field name>, <corresponding payload field name>
				["entry", "entry"],
				["leave", "leave"],
				["totalHours", "totalHours"],
				["dayHours", "dayHours"],
				["nightHours", "nightHours"],
				["breakHours", "breakHours"],
				["overHours", "overHours"],
				["holidayHours", "holidayHours"],
				["comment", "comment"]
			];

			for(let i = 0; i < fieldsMapping.length; i++){
				if(typeof(payload[fieldsMapping[i][1]]) === 'boolean'){
					state.recordDetailsTimesheetSectionColumnsVisibility[fieldsMapping[i][0]] = payload[fieldsMapping[i][1]];
				}
			}
		},

		updateDesktopColumnsWidths(state, payload) {
			// for(let i = 0; i < state.resizableColumnsCache.desktopColumnsWidths.length && i < payload.length; i++){
			//	 state.resizableColumnsCache.desktopColumnsWidths[i] = payload[i];
			// }
			for (let key in payload) {
				state.resizableColumnsCache.desktopColumnsWidths[key] = payload[key]
			}
		},

		updateDesktopColumnsVisible(state, payload) {
			for (let key in payload) {
				state.resizableColumnsCache.desktopColumnsVisible[key] = payload[key]
			}
		},

		updateSummaryDesktopColumnsWidths(state, payload) {
			for (let key in payload) {
				state.resizableColumnsCache.summaryDesktopColumnsWidths[key] = payload[key]
			}
		},

		updateWorkerSummaryDesktopColumnsWidths(state, payload) {
			for (let key in payload) {
				state.resizableColumnsCache.workerSummaryDesktopColumnsWidths[key] = payload[key]
			}
		},

		updateTimesheetsView(state, payload) {
			state.timesheetsView = payload
		},

		updateTimesheetsMode(state, payload) {
			state.timesheetsMode = payload
		},

		/**
		 * Replaces currentPage in state
		 * @param {*} state Provided by Vuex
		 * @param {Object} payload Payload will replace currentPage in state
		 */
		updateCurrentPageCache(state, payload) {
			payload.cachedAt = new Date();
			state.currentPage = payload;
		},

		/**
		 * Replaces currentProjects in state
		 * @param {*} state Provided by Vuex
		 * @param {Object} payload Payload will replace currentProjects in state
		 */
		updateCurrentProjectsCache(state, payload) {
			payload.chachedAt = new Date();
			state.currentProjectsCache = payload;
		},

		/**
		 * Replaces filters in state in state
		 * @param {*} state Provided by Vuex
		 * @param {Object} payload Required format (all values are optional, if not given they will be ignored):
		 * {
		 * 		[searchValue]: {String},
		 * 		[project]: {String},
		 * 		[page]: {Number}
		 * }
		 */
		// updateFilters(state, payload) {
		//	 let fields = ["searchValue", "project", "worker", "page", "perPage", "shifts", "tags", "tagsType", "state"];
		//	 for (let i = 0; i < fields.length; i++) {
		//		 if (typeof(payload[fields[i]]) !== 'undefined') {
		//			 state.filters[fields[i]] = payload[fields[i]];
		//		 }
		//	 }
		// },
		updateTimesheetsFilters(state, payload) {
			let fields = ["project", "worker", "page", "perPage", "shifts", "tags", "tagsType", "state"];
			for (let i = 0; i < fields.length; i++) {
				if (typeof(payload[fields[i]]) !== 'undefined') {
					state.timesheetsFilters[fields[i]] = payload[fields[i]];
				}
			}
		},
		updateAcceptanceFilters(state, payload) {
			let fields = ["project", "worker", "page", "perPage", "state"];
			for (let i = 0; i < fields.length; i++) {
				if (typeof(payload[fields[i]]) !== 'undefined') {
					state.acceptanceFilters[fields[i]] = payload[fields[i]];
				}
			}
		},
		updateCopyRecordsOptions(state, payload) {
			let fields = ["dataType", "cycleType", "dailyType", "everyXDaysNumber"];
			for (let i = 0; i < fields.length; i++) {
				if (typeof(payload[fields[i]]) !== 'undefined') {
					state.copyRecordsOptions[fields[i]] = payload[fields[i]];
				}
			}
		},

		updateCachedValues(state, payload) {
			let fields = ["date", "periodId", "calendarId", "periodType", "periodStartDate"];
			for (let i = 0; i < fields.length; i++) {
				if (typeof(payload[fields[i]]) !== 'undefined') {
					state.cachedValues[fields[i]] = payload[fields[i]];
				}
			}
		}
	},

	actions: {
		/**
		 * Get data for page
		 * @param {*} param0 Provided by Vuex
		 * @param {Object} payload Format: {
		 * 		startDate: {Date},
		 * 		endDate: {Date},
		 * 		[page]: {Number}, // default: 1
		 * 		[perPage]: {Number}, // default: 10
		 * 		[state]: {Number/Array}, // default: null
		 * 		[type]: {Number/Array}, // default: null
		 * 		[search]: {String}, // default: null
		 * 		[worker]: {Number/Array}, // default: null
		 * 		[project]: {Number/Array}, // default: null
		 * 		[shift]: {Number/Array}, // default: null
		 * 		[task]: {Number/Array}, // default: null
		 * }
		 */
		async getData({ state, commit, rootGetters }, payload) {
			let requiredFields = [
				"startDate", "endDate"
			];
			let optionalFields = [
				["page", null],
				["perPage", 10],
				["state", undefined],
				["type", null],
				["search", undefined],
				["worker", null],
				["project", undefined],
				["shifts", null],
				["task", null],
				["tags", null],
				["tagsType", undefined],
				["workersNoRecords", null]
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			// Loading cached values (for fields that were not provided)
			let data = tmp.validatedData;
			if (data.page == null) {
				if (state.filters.page != null) {
					data.page = state.filters.page;
				} else {
					data.page = 1;
				}
			}
			if (typeof(data.project) === 'undefined') {
				if (state.filters.project != null) {
					data.project = state.filters.project;
				} else {
					data.project = null;
				}
			}
			if (typeof(data.search) === 'undefined') {
				if (state.filters.searchValue != null) {
					data.search = state.filters.searchValue;
				} else {
					data.search = null;
				}
			}
			if (typeof(data.perPage) === 'undefined') {
				if (state.filters.perPage != null) {
					data.perPage = state.filters.perPage;
				} else {
					data.perPage = null;
				}
			}
			if (typeof(data.state) === 'undefined') {
				if (state.filters.state != null) {
					data.state = state.filters.state;
				} else {
					data.state = null;
				}
			}

			// Cache new values for selected view
			if(state.timesheetsView=='hours'){
				commit("updateTimesheetsFilters", {
					page: data.page,
					perPage: data.perPage,
					project: data.project,
					worker: data.worker,
					shifts: data.shifts,
					tags: data.tags,
					tagsType: data.tagsType,
					state: data.state,
				});
			}
			else if(state.timesheetsView=='acceptance'){
				commit("updateAcceptanceFilters", {
					page: data.page,
					perPage: data.perPage,
					project: data.project,
					worker: data.worker,
					state: data.state,
				});
			}

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			let params = {
				start_date: data.startDate,
				end_date: data.endDate,
				page: data.page,
				per_page: data.perPage,
				state: data.state,
				type: data.type,
				search: data.search,
				worker: data.worker,
				project: data.project,
				shifts: data.shifts,
				task: data.task,
				tags: data.tags,
				tags_type: data.tagsType,
			}

			if(data.workersNoRecords!=null){
				params.workers_no_records = data.workersNoRecords
			}
			
			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/combined-entries/",
					headers: headers,
					params: params,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let newCache = adaptCombinedEntriesAPIResponse(response.data, data.startDate, data.endDate);

			commit("updateCurrentPageCache", newCache);

			return structuredClone(newCache);
		},

		/**
		 * Get projects list with user's permissions to those projects
		 * @param {*} param0  Provided by Vuex
		 * @returns List of projects and permissions to them
		 */
		async getProjects({ commit, rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/projects/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let newCache = adaptProjectsAPIResponse(response.data);

			commit("updateCurrentProjectsCache", newCache);

			return newCache;
		},

		async getTags({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/tags/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let tags = adaptTagsAPIResponse(response.data);

			return tags;
		},

		async getShifts({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/shifts/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let shifts = adaptShiftsAPIResponse(response.data);

			return shifts;
		},

		/**
		 * Get calendars list
		 * @param {*} param0  Provided by Vuex
		 * @returns List of calendars with periods
		 */
		async getCalendars({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/calendars/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let newCache = adaptCalendarsAPIResponse(response.data);

			// commit("updateCurrentProjectsCache", newCache);

			return newCache;
		},
		
		/**
		 * Copy worker's time entries and/or drafts from selected day
		 * @param {*} param0 
		 * @param {Object} payload Required format
		 * {
		 *	  copyTypes: {Array}
		 *	  date: {String}
		 *	  dateFrom: {String}
		 *	  dateTo: {String}
		 *	  duplicates: {Boolean}
		 *	  weekDays: {Array}
		 *	  dayNumber: {Number}
		 *	  draftIds: {Array}
		 *	  timeEntryIds: {Array}
		 * } 
		 */
		async copyRecords({rootGetters}, payload){
			let requiredFields = [
				"cycleType", "date", "dateFrom", "dateTo", "duplicates", "draftIds", "timeEntryIds",
			];

			let optionalFields = [
				["copyType", null],
				["weekDays", null],
				["dayNumber", null]
			]

			let tmp = validateRequiredAndOptional(payload, requiredFields, optionalFields);
			let d = tmp.validatedData;

			if(!tmp.valid) throw { errorType: "validation", errors: tmp.errors }
			
			let data = {
				date: d.date,
				date_from: d.dateFrom,
				date_to: d.dateTo,
				duplicates: d.duplicates
			}

			if(d.copyType){
				data.copy_type=d.copyType
			}
			if(!d.copyType || d.copyType=='DRAFTS'){
				data.draft_ids=d.draftIds
			}
			if(!d.copyType || d.copyType=='TIME_ENTRIES'){
				data.time_entry_ids=d.timeEntryIds
			}
			
			// Daily cycle type
			if(d.cycleType==0){
				if(d.weekDays!==null){
					data.week_days=d.weekDays
				}
				else if(d.dayNumber!=null){
					data.day_number = d.dayNumber
				}
			}
			// Weekly cycle type
			else if(d.cycleType==1){
				data.week_days=d.weekDays
			}

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + '/api/v1/terminals/summary/time-entries/copy/',
					headers: headers,
					data: data
				});
			} catch (error){
				throw {
					errorType: "request",
					error: error
				}
			}

			return response
		},

		/**
		 * Change time entries status
		 * @param {*} param0 
		 * @param {Object} payload Required format
		 * {
		 *	  timeEntryIds: {Array}
		 *	  stateId: {Number}
		 * } 
		 */
		async changeTimeEntriesStatus({ rootGetters }, payload) {
			let requiredFields = [
				"timeEntryIds", "stateId"
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields);

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = tmp.validatedData;
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/time-entries/state/",
					headers: headers,
					data: {
						time_entry_ids: data.timeEntryIds,
						state_id: data.stateId,
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			// let adaptedCalculatedWorkers = adaptPeriodSummaryAPIResponse(response.data);

			// return adaptedCalculatedWorkers;
			return response.data
		},




		/**
		 * Gets full worker details for a single day
		 * @param {*} param0 
		 * @param {Object} payload Required format
		 * {
		 * 		workerId: {Number},
		 * 		date: {Date/String}
		 * } 
		 */
		async getWorkerDailyDetails({ rootGetters }, payload) {
			let requiredFields = [
				"workerId", "date"
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, null);

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = tmp.validatedData;
			if (typeof(data.date) !== Date) {
				data.date = stringToDate(data.date);
			}
			let endDate = new Date(data.date)
			endDate.addDays(1)
			endDate = endDate.toString('yyyy-MM-dd')
			let y = data.date.getFullYear();
			let m = data.date.getMonth() + 1;
			if (m < 10) m = '0' + m;
			let d = data.date.getDate();
			if (d < 10) d = '0' + d;

			data.date = y + '-' + m + '-' + d;

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/combined-entries/",
					headers: headers,
					params: {
						start_date: data.date,
						// end_date: data.date,
						end_date: endDate,
						page: 1,
						per_page: 1,
						worker: data.workerId,
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			if(response.data.workers.length==0){
				throw {
					errorType: "noWorker",
					error: null
				};
			}
			d = stringToDate(data.date);
			let adapted = __adaptWorker(response.data.workers[0], d, Date.parse(endDate));
			let events = __adaptEvents(response.data.events)
			adapted.events = events

			return adapted;
		},


		/**
		 * Get state list
		 * @param {*} param0  Provided by Vuex
		 * @returns
		 */
		async getStates({ rootGetters }) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/transitions-matrix/",
					headers: headers
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			let adapted = __adaptStates(response.data);
			return adapted
		},

		/**
		 * PATCH request for single draft update
		 * @param {*} param0 
		 * @param {Object} payload Required format
		 * {
		 * 		type: {Number}
		 * 		draftId: {Number}
		 * 		workerId: {Number},
		 * 		registeredAt: {Date},
		 * } 
		 */
		async updateDraft({ rootGetters }, payload) {
			let data = {
				type: payload.type,
				registeredAt: payload.registeredAt,
			}

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/draft/" + payload.draftId,
					headers: headers,
					data: data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},

		async deleteDraft({ rootGetters }, payload) {

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/draft/" + payload.draftId,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},

		/**
		 * POST request for drafts acception
		 * @param {*} param0 
		 * @param {Object} payload Required format
		 * {
		 * 		drafts: [
		 * 			{
		 *				draft_in_id: {Number},
		 *				draft_out_id: {Number},
		 * 			}
		 * 		]
		 * } 
		 */
		async acceptDrafts({ rootGetters }, payload) {

			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/drafts/accept/",
					headers: headers,
					data: payload
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},

		/**
		 * PATCH request for single time entry update
		 * @param {*} param0 
		 * @param {Object} payload Required format
		 * {
				timeEntryId: {Number},
				data: {
					worker_id: {Number},
					project_id: {Number},
					child_type: {Number},
					child: {
						num_mins: {Number},
					},
					details: []
				}
		 * } 
		 */
		async updateTimeEntry({ rootGetters }, payload) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "PATCH",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/time-entry/" + payload.timeEntryId,
					headers: headers,
					data: payload.data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},

		async createTimeEntry({ rootGetters }, payload) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "POST",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/time-entries/",
					headers: headers,
					data: payload.data
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},

		async deleteTimeEntry({ rootGetters }, payload) {
			let headers = rootGetters.standardRequestHeaders;
			let response = null;

			try {
				response = await axios({
					method: "DELETE",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/time-entry/" + payload.timeEntryId,
					headers: headers,
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response
		},

		/**
		 * PUT request for mass timesheets update
		 * @param {*} param0 Provided by Vuex
		 * @param {Object} payload Required format:
		 * {
		 * 		add: [
		 * 			{
		 * 				type: {Number},
		 * 				projectId: {Number},
		 * 				workerId: {Number},
		 * 				registeredAt: {Date},
		 * 				[deleted]: {Boolean}  
		 * 			}
		 * 		],
		 * 		change: [
		 * 			{
		 * 				id: {Number},
		 * 				type: {Number},
		 * 				registeredAt: {Date}
		 * 			}
		 * 		],
		 * 		delete: [
		 * 			{
		 * 				id: {Number}
		 * 			}
		 * 		]
		 * }
		 */
		async bulkUpdateTimesheets({ rootGetters }, payload) {
			let requestData = [];

			// Preparing data
			let tmp;
			for (let i = 0; i < payload.add.length; i++) {
				tmp = payload.add[i];

				requestData.push({
					id: null,
					type: tmp.type,
					project_id: tmp.projectId,
					worker_id: tmp.workerId,
					registered_at: dateToString(tmp.registeredAt),
					deleted: (typeof(tmp.deleted) !== 'undefined') ? tmp.deleted : false
				});
			}

			for (let i = 0; i < payload.change.length; i++) {
				tmp = payload.change[i];

				requestData.push({
					id: tmp.id,
					type: tmp.type,
					registered_at: dateToString(tmp.registeredAt)
				});
			}

			for (let i = 0; i < payload.delete.length; i++) {
				tmp = payload.delete[i];
				requestData.push({
					id: tmp.id,
					deleted: true
				});
			}

			let headers = rootGetters.standardRequestHeaders;
			let response;

			try {
				response = await axios({
					method: 'PUT',
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/drafts/",
					headers: headers,
					data: {
						drafts: requestData
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response;
		},

		async deleteDrafts({ rootGetters }, payload) {
			let headers = rootGetters.standardRequestHeaders;
			let response;

			try {
				response = await axios({
					method: 'PUT',
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + "/api/v1/terminals/summary/drafts/",
					headers: headers,
					data: payload
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			return response;
		},
		/**
		 * Gets worker drafts history for a single day
		 * @param {*} param0 
		 * @param {Object} payload Required format
		 * {
		 * 		workerId: {Number},
		 * 		dateStart: {Date/String}
		 * 		dateEnd: {Date/String}
		 * } 
		 */
		async getWorkerDraftsHistory({ rootGetters }, payload) {
			let requiredFields = [
				"workerId", "date"
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, null);

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = tmp.validatedData;
			if (typeof(data.date) !== Date) {
				data.date = stringToDate(data.date);
			}
			let endDate = new Date(data.date)
			endDate.addDays(1)
			endDate = endDate.toString('yyyy-MM-dd')
			let y = data.date.getFullYear();
			let m = data.date.getMonth() + 1;
			if (m < 10) m = '0' + m;
			let d = data.date.getDate();
			if (d < 10) d = '0' + d;

			data.date = y + '-' + m + '-' + d;
			
			let headers = rootGetters.standardRequestHeaders;
			let response = null;
			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/terminals/summary/drafts/history/`,

					headers: headers,
					params: {
						start_date:	data.date,
						end_date: endDate,
						worker_id: payload.workerId,
					}
				});
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}

			// let adapted = __adaptStates(response.data);
			// console.log(adapted, 'adapted')

			return response;
		},
		async getWorkerHistory({ rootGetters }, payload) {
			let requiredFields = [
				"date", "workerId"
			];
			let tmp = validateRequiredAndOptional(payload, requiredFields, null);

			if (!tmp.valid) throw { errorType: "validation", errors: tmp.errors }

			let data = tmp.validatedData;
			if (typeof(data.date) !== Date) {
				data.date = stringToDate(data.date);
			}
			let endDate = new Date(data.date)
			endDate.addDays(1)
			endDate = endDate.toString('yyyy-MM-dd')
			let y = data.date.getFullYear();
			let m = data.date.getMonth() + 1;
			if (m < 10) m = '0' + m;
			let d = data.date.getDate();
			if (d < 10) d = '0' + d;

			data.date = y + '-' + m + '-' + d;
					
			let headers = rootGetters.standardRequestHeaders;
			
			let response = null;
			try {
				response = await axios({
					method: "GET",
					url: appConfig.getApiUrl(rootGetters["tenants/currentTenantSlug"]) + `/api/v1/terminals/summary/time-entries/audit/`,
					headers: headers,
                    params: {
						date_start: data.date,
						date_stop: endDate,
						worker_id: payload.workerId,
					}
				});
				
			} catch (error) {
				throw {
					errorType: "request",
					error: error
				};
			}
			return response;
		},
	},
}