export default {
    namespaced: true,

    state: {
        filters: {
            project: -1,
            shifts: null,
            tags: null,
            tagsType: 0,
        },
        selectedWorkers: [],
    },

    getters: {
        filters: (state) => {
            return state.filters;
        },
        selectedWorkers: (state) => {
            return state.selectedWorkers;
        },
    },

    mutations: {
        updateFilters(state, payload){
            if(typeof(payload.project) !== 'undefined'){
                state.filters.project = payload.project;
            }
            if(typeof(payload.shifts) !== 'undefined'){
                state.filters.shifts = payload.shifts;
            }
            if(typeof(payload.tags) !== 'undefined'){
                state.filters.tags = payload.tags;
            }
            if(typeof(payload.tagsType) !== 'undefined'){
                state.filters.tagsType = payload.tagsType;
            }
        },
        selectedWorkers(state, payload) {
            state.selectedWorkers = payload
        },
    },

    actions: {}
}