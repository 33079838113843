import Vue from 'vue'
import Router from 'vue-router'

// import Login from '@/components/Login.vue'
// import FirstLogging from '@/components/FirstLogging.vue'
// import Homepage from '@/components/Homepage.vue'
// import Records from '@/components/Records.vue'
// import Settings from '@/components/Settings.vue'
// import About from '@/components/About.vue'
// import Status from '@/components/Status.vue'
// import Summary from '@/components/Summary.vue'
// import RecordDetails from '@/components/RecordDetails.vue'
// import AddLeaving from '@/components/AddLeaving.vue'
// import ResetPassword from '@/components/ResetPassword.vue'
// import NewPassword from '@/components/NewPassword.vue'
// import SendLink from '@/components/SendLink.vue'
// import ChangePassword from '@/components/ChangePassword.vue'
// import Workers from '@/components/Workers.vue'
// import Cards from '@/components/Cards.vue'
// import WorkerDetails from '@/components/WorkerDetails.vue'
// import WorkerProjects from '@/components/WorkerProjects.vue'
// import WorkerPersonalData from '@/components/WorkerPersonalData.vue'
// import AdminPanel from '@/components/AdminPanel.vue'
// import AdminPermissions from '@/components/AdminPermissions.vue'
// import Dictionaries from '@/components/Dictionaries.vue'
// import ModuleConfiguration from '@/components/ModuleConfiguration.vue'
// import DataSharingConfiguration from '@/components/DataSharingConfiguration.vue'
// import Users from '@/components/Users.vue'
// import ChooseTenant from '@/components/ChooseTenant.vue'

const Login = () =>
    import ('@/components/Login.vue');
const FirstLogging = () =>
    import ('@/components/FirstLogging.vue');
const Homepage = () =>
    import ('@/components/UserRCP/Homepage.vue');
const SwitchingTenantSplashScreen = () =>
    import ('@/components/SwitchingTenantSplashScreen.vue');
const RCP_Projects = () =>
    import ('@/components/UserRCP/RCP_Projects.vue');
const RegisterWorktime = () =>
    import ('@/components/UserRCP/RegisterWorktime.vue');
const Timesheets = () =>
    import ('@/components/Timesheets/Timesheets.vue');
const Settings = () =>
    import ('@/components/Settings.vue');
const About = () =>
    import ('@/components/About.vue');
const Status = () =>
    import ('@/components/CurrentPresence/Status.vue');
// const Summary = () =>
//     import ('@/components/Summary.vue');
const RecordDetails = () =>
    import ('@/components/RecordDetails/RecordDetails.vue');
const AddLeaving = () =>
    import ('@/components/AddLeaving.vue');
const ResetPassword = () =>
    import ('@/components/ResetPassword.vue');
const NewPassword = () =>
    import ('@/components/NewPassword.vue');
const SendLink = () =>
    import ('@/components/SendLink.vue');
const ChangePassword = () =>
    import ('@/components/ChangePassword.vue');
const Workers = () =>
    import ('@/components/Workers/Workers.vue');
const ImportWorkers = () =>
    import ('@/components/ImportWorkers.vue');
const Cards = () =>
    import ('@/components/WorkerCards/Cards.vue');
const WorkerDetails = () =>
    import ('@/components/WorkerDetails.vue');
const WorkerProjects = () =>
    import ('@/components/WorkerProjects.vue');
const WorkerPersonalData = () =>
    import ('@/components/WorkerPersonalData.vue');
const Reports = () =>
    import ('@/components/Reports/Reports.vue');
const AdminPanel = () =>
    import ('@/components/AdminPanel.vue');
const AdminPermissions = () =>
    import ('@/components/AdminPermissions.vue');
const Dictionaries = () =>
    import ('@/components/Dictionaries/Dictionaries.vue');
const ProjectsTab = () =>
    import ('@/components/Dictionaries/ProjectsTab.vue');
const DepartmentsTab = () =>
    import ('@/components/Dictionaries/DepartmentsTab.vue');
const ShiftsTab = () =>
    import ('@/components/Dictionaries/ShiftsTab.vue');
const CategoriesTab = () =>
    import ('@/components/Dictionaries/CategoriesTab.vue');
const EventsCalendarsTab = () =>
    import ('@/components/Dictionaries/EventsCalendarsTab.vue');
const LeaveTypesTab = () =>
    import ('@/components/Dictionaries/LeaveTypesTab.vue');
const CardReadersTab = () =>
    import ('@/components/Dictionaries/CardReadersTab.vue');
const BreaksTab = () => 
    import ('@/components/Dictionaries/BreaksTab.vue');
const ModuleConfiguration = () =>
    import ('@/components/ModuleConfiguration.vue');
const DataSharingConfiguration = () =>
    import ('@/components/DataSharingConfiguration.vue');
const Users = () =>
    import ('@/components/Users.vue');
const ChooseTenant = () =>
    import ('@/components/ChooseTenant.vue');
const ImportTimesheets = () =>
    import ('@/components/ImportTimesheets/ImportTimesheets.vue');
const Leaves = () =>
    import ('@/components/Leaves/Leaves.vue');
const LeaveManagement = () =>
    import ('@/components/LeaveManagement/LeaveManagement.vue');
const Preferences = () =>
    import ('@/components/Preferences.vue');
const UserRCPRecords = () =>
    import ('@/components/UserRCP/UserRCPRecords/UserRCPRecords.vue');
const Schedules = () =>
    import ('@/components/Schedules/Schedules.vue');
const ProjectApprovalProcess = () =>
    import ('@/components/ApprovalProcess/ProjectApprovalProcess/ProjectApprovalProcess.vue');
const LeaveTypeApprovalProcess = () =>
    import ('@/components/ApprovalProcess/LeaveTypeApprovalProcess/LeaveTypeApprovalProcess.vue');
const ApprovalProcess = () =>
    import ('@/components/ApprovalProcess/ApprovalProcess.vue');
const ApprovalProcessTimesheets = () =>
    import ('@/components/ApprovalProcess/ProjectsList.vue');
const ApprovalProcessLeaves = () =>
    import ('@/components/ApprovalProcess/LeavesList.vue');
const Calendars = () =>
    import ('@/components/Calendars/Calendars.vue');
const Debug = () => import('@/components/Debug.vue');


Vue.use(Router)


export default new Router({

    routes: [
        { path: '/', redirect: { name: 'login' } },
        { path: '/debug', name: 'debug', component: Debug },

        { path: '/login', name: 'login', component: Login },
        { path: '/choose-tenant', name: 'chooseTenant', component: ChooseTenant },
        { path: '/switching-tenant', name: 'switchingTenantSplashScreen', component: SwitchingTenantSplashScreen },
        { path: '/account-activate/:token', name: 'firstLogging', component: FirstLogging },
        { path: '/rcp', name: 'homepage', component: Homepage },
        { path: '/rcp/projects', name: 'RCP_Projects', component: RCP_Projects },
        { path: '/register-worktime/:time_entry_id?', name: 'registerWorktime', component: RegisterWorktime, props: true },
        { path: '/timesheets', name: 'timesheets', component: Timesheets },
        { path: '/timesheets/import', name: 'timesheets-import', component: ImportTimesheets },
        { path: '/settings', name: 'settings', component: Settings },
        { path: '/about', name: 'about', component: About },
        { path: '/presence', name: 'status', component: Status },
        //{ path: '/summary', name: 'summary', component: Summary },
        { path: '/timesheets/record-details/:worker_id/:date', name: 'recordDetails', component: RecordDetails, props: true },
        { path: '/presence/add-leaving', name: 'addLeaving', component: AddLeaving, props: true },
        { path: '/reset-password', name: 'resetPassword', component: ResetPassword },
        { path: '/new-password/:token', name: 'newPassword', component: NewPassword },
        { path: '/send-link', name: 'sendLink', component: SendLink },
        { path: '/settings/change-password', name: 'changePassword', component: ChangePassword },
        { path: '/settings/preferences', name: 'preferences', component: Preferences },
        { path: '/workers', name: 'workers', component: Workers },
        { path: '/workers/import', name: 'importWorkers', component: ImportWorkers },
        {
            path: '/workers/details',
            name: 'workerDetails',
            children: [
                { path: 'cards', name: 'cards', component: Cards, props: true },
                { path: 'projects', name: 'workerProjects', component: WorkerProjects, props: true },
                { path: 'personal-data', name: 'workerPersonalData', component: WorkerPersonalData, props: true }
            ],
            component: WorkerDetails
        },
        { path: '/leaves', name: 'leaves', component: Leaves },
        { path: '/leaves/management/:leave_id?', name: 'leaveManagement', component: LeaveManagement, props: true },
        { path: '/reports', name: 'reports', component: Reports },
        { path: '/admin', name: 'admin', component: AdminPanel },
        { path: '/admin/users', name: 'users', component: Users },
        { path: '/admin/permissions', name: 'permissions', component: AdminPermissions },
        { path: '/admin/permissions/mdl-config/:group_id?', name: 'ModuleConfiguration', component: ModuleConfiguration },
        { path: '/admin/permissions/ds-config/:group_id?', name: 'DataSharingConfiguration', component: DataSharingConfiguration },
        {
            path: '/admin/dictionaries',
            redirect: { name: 'projectsTab' },
            children: [
                { path: 'projects', name: 'projectsTab', component: ProjectsTab, props: true },
                { path: 'departments', name: 'departmentsTab', component: DepartmentsTab, props: true },
                { path: 'shifts', name: 'shiftsTab', component: ShiftsTab, props: true },
                { path: 'categories', name: 'categoriesTab', component: CategoriesTab, props: true },
                { path: 'card-readers', name: 'cardReadersTab', component: CardReadersTab, props: true },
                { path: 'leave-types', name: 'leaveTypesTab', component: LeaveTypesTab, props: true },
                { path: 'events-calendars', name: 'eventsCalendarsTab', component: EventsCalendarsTab, props: true },
                { path: 'breaks', name: 'breaksTab', component: BreaksTab, props: true },
            ],
            component: Dictionaries
        },
        { path: '/admin/calendars/:calendar_id?', name: 'calendars', component: Calendars },
        { path: '/rcp/records', name: 'userRCPRecords', component: UserRCPRecords },
        { path: '/schedules', name: 'schedules', component: Schedules },
        { path: '/admin/approval-process/project-config/:project_id/:project_name', name: 'projectApprovalProcess', component: ProjectApprovalProcess, props: true },
        { path: '/admin/approval-process/leaves/:leave_type_id/:leave_type_name/:go_back_page?', name: 'leaveTypeApprovalProcess', component: LeaveTypeApprovalProcess, props: true },
        { 
            path: '/admin/approval-process', 
            name: 'approvalProcess', 
            redirect: { name: 'approvalProcessTimesheetsList' },
            children: [
                { path: 'leaves', name: 'approvalProcessLeavesList', component: ApprovalProcessLeaves, props: true },
                { path: 'timesheets', name: 'approvalProcessTimesheetsList', component: ApprovalProcessTimesheets, props: true },
            ],
            component: ApprovalProcess },
    ]
})