<template>
	<div class="timer" 	v-show="RP.timer.active || RP.timer.stopAt"				
		@click="permissions != null && permissions.includes('timesheet_rcp_add')? redirectToRegisterWorktime(): null"
	>
		<div class="timer-content">
			<div class="font-weight-medium nowrap-elipsis">{{$t('worktime')}}:</div>

			<div style="margin: auto 0; text-align: center; max-width: 150px;" ><p ref="Counter" id="counter"></p></div>
			<!-- <div style="margin: auto 0; text-align: center; max-width: 150px;">{{timeLabel}}</div> -->
			
			<div>
				<v-btn v-if="RP.timer.active"
					icon
					@click="stopTimer()"
				>
					<v-icon large class="red--text">mdi-stop-circle</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
const axios = require('axios');
import appConfig from '@/app_config'

export default {
	props: {
		permissions: {
			required: true
		},
		workerId: {
			required: true
		}
	},
	data(){
		return {
			// stopTimer: false,
		}
	},
	computed:{
		timeLabel(){
			let seconds = this.RP.timer.counter
			let h = Math.floor(seconds / 3600)
			let m = Math.floor((seconds - h*3600) / 60)
			let s = seconds - h*3600 - m*60

			if (h < 10) h = "0"+h
			if (m < 10) m = "0"+m
			if (s < 10) s = "0"+s

			return h+":"+m+":"+s
		},
		RP(){
			//return this;
			return this.$parent.$parent
		}
	},
	mounted(){
		window.setInterval(() => {
			this.getTimerFromAPI()
		}, 30000)

		window.setInterval(() => {
			this.increaseTimer()
		}, 1000)
	},
	watch: {
		timeLabel(){
			let element=document.getElementById('counter')
			element.innerText = this.timeLabel
		},
	},
	methods: {
		clear(){
			this.RP.timer.active = false;
			this.RP.timer.startAt = null;
			this.RP.timer.stopAt = null;
			this.RP.timer.counter = 0;
		},

		async getTimerFromAPI(){
			if(this.permissions == null || !this.permissions.includes("timesheet_rcp_view") || this.workerId == null || this.workerId == 'null'){
				return;
			}

			let response = null
			try{
				response = await axios({
					method: 'get',
					url: appConfig.getApiUrl(this.$store.getters["tenants/currentTenantSlug"]) + "/api/v1/rcp/timer/",
					headers: this.$store.getters["standardRequestHeaders"]
				})
			} catch (error){
				this.handleTimerError(error)
			}

			if(response!=null){
				this.adaptApiResponseTimer(response.data)
			}
		},
		adaptApiResponseTimer(data){
			if(data.timer!=null){
				let tmp = data.timer[0]
				let timer = {
					id: tmp.id,
					projectId: tmp.project.id,
					projectName: tmp.project.name,
					registeredAt: tmp.registered_at,
					type: tmp.type
				}

				this.RP.timer.projectId=timer.projectId

				if(timer.type==0){
					this.RP.timer.active=true
					this.RP.timer.startAt = timer.registeredAt
					this.RP.timer.stopAt = null
					this.RP.timer.counter=Math.floor(((new Date()).getTime() - (new Date(timer.registeredAt)).getTime()) / 1000)
				}
				else if(timer.type==1){
					this.RP.timer.active=false
					this.RP.timer.stopAt = timer.registeredAt

					if(data.timer[1] && data.timer[1].type==0){
						this.RP.timer.startAt = data.timer[1].registered_at
					}

					if(this.RP.timer.startAt!=null){
						this.RP.timer.counter = Math.floor(((new Date(this.RP.timer.stopAt)).getTime() - (new Date(this.RP.timer.startAt)).getTime()) / 1000)
					}

					else{
						this.RP.timer.counter=0
					}
				}
			}
			else{
				this.RP.timer.active=false
				this.RP.timer.startAt = null
				this.RP.timer.stopAt = null
				this.RP.timer.counter=0
				this.RP.timer.projectId=null
			}
		},
		increaseTimer(){
			if(this.RP.timer.active){
				this.RP.timer.counter++
			}
		},
		async stopTimer(){
			let data={
				type: 1,
				project_id: this.RP.timer.projectId
			}
			let response = null
			try{
				response = await axios({
					method: 'post',
					url: appConfig.getApiUrl(this.$store.getters["tenants/currentTenantSlug"]) + "/api/v1/rcp/timer/",
					headers: this.$store.getters["standardRequestHeaders"],
					data: data
				})
			} catch (error){
				this.handleTimerError(error)
			}

			if(response!=null){
				this.RP.timer.active=false
				this.RP.timer.stopAt=response.data.timer.registered_at
				if(this.RP.timer.startAt!=null){
					this.RP.timer.counter = Math.floor(((new Date(this.RP.timer.stopAt)).getTime() - (new Date(this.RP.timer.startAt)).getTime()) / 1000)
				}

				else{
					this.RP.timer.counter=0
				}
				this.redirectToRegisterWorktime()			
			}
			
		},

		handleTimerError(error){
			let errorMessage = this.$t('timerError')+": "

			if (typeof(error.response) === 'undefined'){
				errorMessage += this.$t('networkError');
				this.$emit('display-error', errorMessage);
				return;
			}

			switch(error.response.status){
				case 401:
					// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						errorMessage += this.$t('youDoNotHavePriviledgesToDoThatOperation');
						break;
					case 429:
						errorMessage += this.$t('tooManyRequestsError');
						break;
					case 400:
						if(error.response.data.timer){
							errorMessage += this.$t(error.response.data.timer[0].code);
						}
						else if(error.response.data.project_id){
							errorMessage += this.$t(error.response.data.project_id[0].code)
						}
						else  {
							errorMessage += this.$t('requestRejected');
						}
						break;
					case 500:
						errorMessage += this.$t('internalServerError');
						break;
					default:
						this.$store.dispatch("standardRequestErrorHandler", { component: this, error: error });
						return;
				}
				this.$emit('display-error', errorMessage);

		},
		redirectToRegisterWorktime(){
			if(this.$router.currentRoute.name!='registerWorktime'){
				var backPath = this.$router.currentRoute.path;
				this.$router.push({path: `/register-worktime/?go_back_page=${backPath}`})
			}
		}
	}
	
}
</script>

<i18n>
{
	"en": {
		"worktime": "Working time",
		"youDoNotHavePriviledgesToDoThatOperation": "You do not have priviledges to do that operation",
		"networkError": "Application failed to connect to the server. Please check your internet connection.",
		"requestRejected": "Server rejected your request. Please make sure your application is up to date (reload the website).",
		"tooManyRequestsError": "Server received too many reuqests from your browser. Please wait a minute before trying again.",
		"internalServerError": "An internal server error has occured. Please report this message to the administrator.",
		"errorOccured": "An unknown error occured. That's all we know.",

		"timerError": "TIMER ERROR",
		"TIMER_NEVER_STARTED": "Timer never started.",
		"TIMER_ALREADY_STARTED": "Timer already started.",
		"TIMER_ALREADY_STOPPED": "Timer already stopped.",
		"ACCEPT_OR_CANCEL_CURRENT_TIMER": "Confirm or cancel your last timer records.",

		"CATEGORY_DOES_NOT_EXIST": "Category does not exist",
		"PROJECT_DOES_NOT_EXIST": "Project does not exist",
		"WORKER_PARTICIPATE_PROJECT": "Worker doesn't participate in entered project",
		"CATEGORY_NOT_ASSIGNED_TO_PROJECT": "This category is not assigned to entered project"


	},
	"pl": {
		"worktime": "Czas pracy",
		"youDoNotHavePriviledgesToDoThatOperation": "Nie masz uprawnień by wykonać tą operację",
		"networkError": "Aplikacja nie mogła połączyć się z serwerem. Sprawdź swoje połączenie z internetem.",
		"requestRejected": "Serwer odrzucił zapytanie. Upewnij się, że posiadasz aktualną wersję aplikacji (przeładuj stronę).",
		"tooManyRequestsError": "Zbyt wiele zapytań dla niezautoryzowanego połączenia. Odczekaj chwilę przed następną próbą.",
		"internalServerError": "Wystąpił błąd serwera. Zgłoś problem do administratora.",
		"errorOccured": "Wystąpił błąd. Tylko tyle wiemy.",

		"timerError": "BŁĄD LICZNIKA",

		"TIMER_NEVER_STARTED": "Nie można zakończyć pracy licznika, ponieważ nie został uruchomiony.",
		"TIMER_ALREADY_STARTED": "Licznik został już uruchomiony.",
		"TIMER_ALREADY_STOPPED": "Licznik został już zatrzymany.",
		"ACCEPT_OR_CANCEL_CURRENT_TIMER": "Przed ponownym uruchomieniem licznika potwierdź lub odrzuć ostatni zapis.",

		"CATEGORY_DOES_NOT_EXIST": "Kategoria nie istnieje",
		"PROJECT_DOES_NOT_EXIST": "Projekt nie istnieje",
		"WORKER_PARTICIPATE_PROJECT": "Pracownik nie bierze udziału w wybranym projekcie",
		"CATEGORY_NOT_ASSIGNED_TO_PROJECT": "Kategoria nie jest przypisana do wybranego projektu"


	},
	"de": {
		"worktime": "Arbeitszeiten",
		"youDoNotHavePriviledgesToDoThatOperation": "Sie haben keine Berechtigungen für diese Operation",
		"networkError": "Ein Netzwerkfehler ist aufgetreten. Überprüfen Sie Ihre Internetverbindung, bevor Sie es erneut versuchen.",
		"requestRejected": "Der Server hat Ihre Anfrage abgelehnt. Bitte stellen Sie sicher, dass Ihre Bewerbung aktuell ist (Website neu laden).",
		"tooManyRequestsError": "Der Server hat zu viele Anfragen von Ihrem Browser erhalten. Bitte warten Sie eine Minute, bevor Sie es erneut versuchen.",
		"internalServerError": "Ein Serverfehler ist aufgetreten. Bitte melden Sie diese Nachricht dem Administrator.",
		"errorOccured": "Ein Fehler ist aufgetreten. Das ist alles, was wir wissen.",

		"timerError": "TIMER-FEHLER",

		"TIMER_NEVER_STARTED": "Der Zähler kann nicht beendet werden, da er nicht gestartet wurde.",
		"TIMER_ALREADY_STARTED": "Der Zähler wurde bereits gestartet.",
		"TIMER_ALREADY_STOPPED": "Der Zähler ist bereits angehalten worden.",
		"ACCEPT_OR_CANCEL_CURRENT_TIMER": "Bestätigen oder verwerfen Sie die letzte Eingabe, bevor Sie den Zähler neu starten.",	
	
		"CATEGORY_DOES_NOT_EXIST": "Kategorie ist nicht vorhanden",
		"PROJECT_DOES_NOT_EXIST": "Das Projekt existiert nicht",
		"WORKER_PARTICIPATE_PROJECT": "Der Mitarbeiter nimmt nicht an dem ausgewählten Projekt teil",
		"CATEGORY_NOT_ASSIGNED_TO_PROJECT": "Kategorie ist dem ausgewählten Projekt nicht zugewiesen"

	}
}
</i18n>

<style scoped>
.nowrap-elipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.timer{
	width: calc(100% - 256px);
	height: 50px;
	position: fixed;
	background: #ffffff;
	bottom: 0;
	left: 256px;
	display: flex;
	justify-content: center;
	box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

@media only screen and (max-width: 960px){
	.timer{
		width: 100%;
		left: 0;
	}
}

.timer-content{
	display: grid;
	grid-template-columns: min-content minmax(0, 150px) 36px;

}

.timer-content > div {
	line-height: 50px;
}
</style>